import { Component, NgModule, ViewChildren } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, ChildActivationStart } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { content } from "./shared/routes/content-routes";
import { full } from './shared/routes/full.routes';
import { AdminGuard } from './shared/guard/admin.guard';
import { RegistrationComponent } from './auth/registration/registration.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ManageVehicleComponent } from './components/AdminUser/vehicles/manage-vehicle/manage-vehicle.component';
const routes: Routes = [
  { 
    path: '',
    redirectTo: '/admin/user-vehicles',
    pathMatch: 'full',
    canActivate:[AdminGuard]
  },
 

  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'forgotpassword',
    component: ForgetPasswordComponent
  }, 
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: full
  },

  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
