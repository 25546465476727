import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from "@angular/fire/firestore";
import { InviteUserService } from 'src/app/shared/services/firebase/invite-user.service';
import { VehiclesService } from 'src/app/shared/services/firebase/vehicles.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {
  profileimagePath: any;
  vehicleimage:any;
  @Input() title: string;
  @Input() componentVal: string;
  @Input() userid: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() data: string;
  VehiclesInformation:any;
  UserInformation:any;
  constructor(private activeModal: NgbActiveModal, private firestore: AngularFirestore, private InviteUserService: InviteUserService, private VehiclesService: VehiclesService) { }

  ngOnInit() {
    // console.log(this.title,"Titleeeee")
    // console.log(this.componentVal,"componentVal")
    // console.log(this.userid,"userid")
    // console.log(this.message,"message")
    // console.log(this.btnOkText,"btnOkText")
    // console.log(this.btnCancelText,"btnCancelText")
    // console.log(this.data,"data")

    for (const items of this.data) {
      this.VehiclesInformation=items;
    }
    for (const userItems of this.VehiclesInformation.userinformation) {
      this.UserInformation=userItems;
    }
    console.log(this.UserInformation,"ppppppppp",this.VehiclesInformation)
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

 


}