import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { AngularFirestore  } from "@angular/fire/firestore";
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map,finalize } from 'rxjs/operators';
import * as firebase from 'firebase';
import { async } from 'rxjs/internal/scheduler/async';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'
import {DialogBoxComponent} from '../../dialog-box/dialog-box.component'
import {AngularFireStorage,BUCKET,AngularFireStorageReference,AngularFireUploadTask, StorageBucket,} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  imageUrl$: Observable<string>;
   url = '';
  constructor(private modalService: NgbModal,private storage:AngularFireStorage,private firestore: AngularFirestore,private http: HttpClient,  public afAuth: AngularFireAuth,) { }

  AddVehicles(data) {
    return this.firestore.collection('RegisterVehicles').add({
    //  Registration_Num:data.value.reg_number,
    //  Id_nr:data.value.id_nr,
      Category:data.value.Category,
      Brand:data.value.Brand,
      Model:data.value.Modal,
      Body_type:data.value.body_type,
      Model_type:data.value.modal_type,
      Chassis:data.value.Chassis,
      Vehicle_height:data.value.Vehicle_height+' M',
      Vehicle_width:data.value.Vehicle_width+' M',
      Vehicle_length:data.value.Vehicle_length+' M',
      Vehicle_weight:data.value.Vehicle_weight+' Kg',
      Front_image:data.value.front_side_image,
      Back_image:data.value.back_side_image,
      Left_image:data.value.left_side_image,
      Right_image:data.value.right_side_image,
      FrontPerspective_image:data.value.FrontPerspective,
      BackPerspective_image:data.value.BackPerspective,
      date: new Date().toDateString()
  });
}

AddVehiclesIcon(data) {
  return this.firestore.collection('RegisterVehicleIcon').add({
    vehicleIcon:data.value.vehicleIcon,
    vehicleType:data.value.vehicleType,
    date: new Date().toDateString() 
});
}


AddVehiclesForUser(data,CustomerId) {
  return this.firestore.collection('RegisterVehicles').add({
  //  Registration_Num:data.value.reg_number,
  //  Id_nr:data.value.id_nr,
    Category:data.value.Category,
    Brand:data.value.Brand,
    Model:data.value.Modal,
    Body_type:data.value.body_type,
    Model_type:data.value.modal_type,
    Chassis:data.value.Chassis,
    RegisterVehcileid:data.value.RegisterVehcileid,
    Vehicle_height:data.value.Vehicle_height+' M',
    Vehicle_width:data.value.Vehicle_width+' M',
    Vehicle_length:data.value.Vehicle_length+' M',
    Vehicle_weight:data.value.Vehicle_weight+' Kg',
    Front_image:data.value.front_side_image,
    Back_image:data.value.back_side_image,
    Left_image:data.value.left_side_image,
    Right_image:data.value.right_side_image,
    FrontPerspective_image:data.value.FrontPerspective,
    BackPerspective_image:data.value.BackPerspective,
    CustomerId:CustomerId,
    date: new Date().toDateString()
});
}
//  Add Vehicle For Company 
AddVehiclesForComapany(data,CustomerId,userinformation) {
  console.log(data.Category,"dataaaa",CustomerId,"idd",userinformation)
  return this.firestore.collection('CustomerVehicles').add({
  //  Registration_Num:data.value.reg_number,
  //  Id_nr:data.value.id_nr,
    Category:data.Category,
    Brand:data.Brand,
    Model:data.Modal,
    Body_type:data.body_type,
    Model_type:data.modal_type,
    Chassis:data.Chassis,
    RegisterVehcileid:data.RegisterNumber,
    IdNumber:data.IdNumber,
    Vehicle_height:data.Vehicle_height+' M',
    Vehicle_width:data.Vehicle_width+' M',
    Vehicle_length:data.Vehicle_length+' M',
    Vehicle_weight:data.Vehicle_weight+' Kg',
    Front_image:data.front_side_image,
    Back_image:data.back_side_image,
    Left_image:data.left_side_image,
    Right_image:data.right_side_image,
    FrontPerspective_image:data.FrontPerspective,
    BackPerspective_image:data.BackPerspective,
    CustomerId:CustomerId,
    Status:'Pending',
    userinformation:userinformation,
    date: new Date().toDateString()
});
}

DuplicateAddVehicles(data) {
  return this.firestore.collection('RegisterVehicles').add({
    Category:data.Category,
   // Status:data.Status,
 //  Registration_Num:data.reg_number,
   //   Id_nr:data.id_nr,
    Brand:data.Brand,
    Body_type:data.Body_type,
    Chassis:data.Chassis,
    Model_type:data.Model_type,
    Model:data.Model,
    Vehicle_height:data.Vehicle_height,
    Vehicle_length:data.Vehicle_length,
    Vehicle_weight:data.Vehicle_weight,
    Vehicle_width:data.Vehicle_width,
    Front_image:data.Front_image,
    Back_image:data.Back_image,
    Left_image:data.Left_image,
    Right_image:data.Right_image,
    BackPerspective_image:data.BackPerspective_image,
    FrontPerspective_image:data.FrontPerspective_image,
    date: new Date().toDateString()
});
}

getvehicledata(id: number){
  const productsDocuments = this.firestore.doc('RegisterVehicles/' + id);
  return productsDocuments.snapshotChanges()
    .pipe(
      map(changes => {
        const data = changes.payload.data();
        const id = changes.payload.id; 
        return { id, data };
      }))
  
  
}
getvehicledataformedia(){
      return this.firestore.collection('RegisterVehicles/').snapshotChanges();
}

getRegisterVehiclesIcon() {
  return this.firestore.collection('RegisterVehicleIcon').snapshotChanges();
}


deleteVehiclesIcon(id) {
  return this.firestore.collection('RegisterVehicleIcon').doc(id).delete();
}

GetRegisterVehicleData(){
  return this.firestore.collection('CustomerVehicles/').snapshotChanges();
}
updateVehicles(id, data) {
  return this.firestore
    .collection("RegisterVehicles")
    .doc(id)
    .set({ Category: data.controls.Category.value, 
      Brand: data.controls.Brand.value, 
      Model: data.controls.Modal.value, 
      Model_type: data.controls.modal_type.value, 
      Body_type: data.controls.body_type.value, 
      Chassis: data.controls.Chassis.value, 
      Vehicle_height: data.controls.Vehicle_height.value, 
      Vehicle_width: data.controls.Vehicle_width.value, 
      Vehicle_length: data.controls.Vehicle_length.value, 
      Vehicle_weight: data.controls.Vehicle_weight.value, 
      Front_image: data.controls.front_side_image.value,
      Back_image: data.controls.back_side_image.value,
      Right_image: data.controls.right_side_image.value,
      Left_image: data.controls.left_side_image.value,
      FrontPerspective_image: data.controls.FrontPerspective_image.value,
      BackPerspective_image: data.controls.BackPerspective_image.value,
    }, { merge: true });

}


connectSensorID(id, data) {
  // debugger;
  // console.clear();
  //  console.log(id,"XX");

  //  console.log(data, "datadatadata");
   
   
  // return this.firestore
  //   .collection("RegisterVehicles")
  //   .doc(id)
  //   .set({ 
  //     SensorConnected: true,
  //    }, { merge: true });

}


// for delete vehicles folder from storage
deleteVehiclesStorage(path) {
  const ref = firebase.storage().ref(path);
  ref.listAll()
    .then(dir => {
      dir.items.forEach(fileRef => {
        this.deleteFile(ref.fullPath, fileRef.name);
      });
      dir.prefixes.forEach(folderRef => {
        this.deleteVehiclesStorage(folderRef.fullPath);
      })
    })
    .catch(error => {
      console.log(error);
    });

}

deleteFile(pathToFile, fileName) {
  const ref = firebase.storage().ref(pathToFile);
  const childRef = ref.child(fileName);
  childRef.delete()
}
public confirm(
  title: string,
  component:string,
  val:string,
  data:string,
  message: string, 
 
  btnOkText: string = 'OK',
  btnCancelText: string = 'Cancel',
  dialogSize: 'sm'|'md'|'lg' = 'md'): Promise<boolean> {
    
  
  const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.componentVal = component;
    modalRef.componentInstance.userid = val;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.data = data;
    
 
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
   


  return modalRef.result;
}
public ModelDialog(
  title: string,
  component:string,
  val:string,
  data:string,
  message: string, 
 
  btnOkText: string = 'OK',
  btnCancelText: string = 'Cancel',
  dialogSize: 'xl'|'md'|'lg' = 'xl'): Promise<boolean> {
    
  
  const modalRef = this.modalService.open(DialogBoxComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.componentVal = component;
    modalRef.componentInstance.userid = val;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.data = data;
    
 
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
   


  return modalRef.result;
}

Deletetheimage(id,data){
  if(data=='BackPerspective_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'BackPerspective_image':null,});}
  else if(data=='Back_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Back_image':null,});}
  else if(data=='FrontPerspective_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'FrontPerspective_image':null,});}
  else if(data=='Front_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Front_image':null,});}
  else if(data=='Left_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Left_image':null,});}
  else if(data=='Right_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Right_image':null,});}
}
updateVehicleImage(id,data,imageName){
 
  if(imageName=='BackPerspective_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'BackPerspective_image':data,});}
  else if(imageName=='Back_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Back_image':data,});}
  else if(imageName=='FrontPerspective_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'FrontPerspective_image':data,});}
  else if(imageName=='Front_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Front_image':data,});}
  else if(imageName=='Left_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Left_image':data,});}
  else if(imageName=='Right_image'){return this.firestore.collection('RegisterVehicles').doc(id).update({'Right_image':data,});}
}
}


