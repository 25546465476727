export class User{
    type:string;
    firstName:string;
    lastName:number;
    email:string;
    password:any;
    oragnizationName:any;
    companyname:any;
    phonenumber:any;
    address:any;
    city:any;
    country:any;
    postalcode:any;
    active = true;
}