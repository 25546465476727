import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  CurrentYear:any;
  constructor() { }

  ngOnInit() { 
    var currentTime = new Date()
    this.CurrentYear =currentTime.getFullYear()
  }

}
