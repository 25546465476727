import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: 'app-transfer-sensors',
  templateUrl: './transfer-sensors.component.html',
  styleUrls: ['./transfer-sensors.component.scss']
})
export class TransferSensorsComponent implements OnInit {
  statusChecked: boolean = false;

  selectedFromCompany: string = '';
  selectedToCompany: string = '';


  updatedData: any[] = [];
  showUpdatedData: boolean = false;

  isModalOpen = false;
  modalData: any = {};
  registerdcompany = [];
  filteredRows: any[] = [];
  rows = [];
  sensoruser = [];
  filterdata: any;
  sensorsRecord: any;
  dataRender: boolean = false;
  columns = [];
  ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  modal: string;
  sensoruserData: any[];
  constructor(private afs: AngularFirestore, private router: Router, public formbuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.GetSensorData();
    // Load saved status from storage or API
    this.loadStatus();
    // load sensor data
    this.getNewSensors();
    // load company data
    this.RegisterCompanydata();
  }

  // Get New Sensors ------------------------------
  getNewSensors() {
    this.afs
      .collection("NewSensors")
      .get()
      .subscribe((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            if (doc.data()) {
              const sensorData = doc.data();
              const newRow = Object.assign({}, sensorData, {
                id: doc.id,
                company: sensorData.companyname,
                organizationNo: sensorData.oragnizationnumber,
                sensorId: sensorData.sensorid,
                transferCompany: sensorData.TransferCompany,
                transferOrgNo: sensorData.tranoragnizationnumber,
                status: "statusIcon",
              });
              this.rows = [...this.rows, newRow];
              this.filteredRows = this.rows
              console.log('datarow', this.rows)
            }
          });
        }
      });
  }

  btnClick(): void {
    const updatePromises = [];
    this.updatedData = []; // Clear previous data

    this.filteredRows.forEach(row => {
      if (row.statusChecked) { // Check if the row is selected
       
        const formData = {
          sensorId:row.sensorId,
          companyname: this.modalData.compyname,
          transferCompany: this.modalData.compyname,
          transferOrgNo: this.modalData.orgnumber,
          transStatus: "success", // Update this value based on your logic
        };
        updatePromises.push(
          this.afs.collection("NewSensors").doc(row.id).update(formData)
        );
      }
    });

    Promise.all(updatePromises)
      .then(() => {
        // Update showUpdatedData to true to display updated data
        this.showUpdatedData = true;

        // Fetch updated data for all selected rows
        const fetchPromises = this.filteredRows
          .filter(row => row.statusChecked)
          .map(row => this.afs.collection("NewSensors").doc(row.id).get().toPromise());

        Promise.all(fetchPromises)
          .then(docs => {
            docs.forEach(doc => {
              const updatedRow = doc.data();
              this.updatedData.push(updatedRow);
            });

            alert("Data updated successfully.");
            this.rows = [];
            this.getNewSensors();

            // Set a timeout to revert back to default table after some time
            setTimeout(() => {
              this.showUpdatedData = false;
            }, 1000); // Adjust the time as per your requirement
          })
          .catch((error) => {
            console.error("Error updating data:", error);
            alert("Error updating data. Please try again.");
          });
      }
      )
  }

  // Registered Company Data ------------------------------
  RegisterCompanydata() {
    this.afs
      .collection("CompanyRegistration")
      .get()
      .subscribe((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data();
          if (user.emailVerified) {
            Object.assign(user, { id: doc.id });
            this.registerdcompany.push(user);
          }
        });
        this.dataRender = true;
        this.filterdata = this.registerdcompany; // Ensure initial data is displayed
      });
  }

  GetSensorData(): void {
    this.afs
      .collection("DevicesData", (ref) => ref)
      .get()
      .subscribe((querySnapshot) => {
        this.sensoruserData = [];

        if (querySnapshot.docs.length > 0) {
          const uniqueSIDSet = new Set(); // To store unique SID values

          querySnapshot.forEach((doc) => {
            const sensorObj = doc.data()["payload"];

            // Check if the SID property exists and if it's not already added
            if (
              sensorObj &&
              sensorObj.SID &&
              !uniqueSIDSet.has(sensorObj.SID)
            ) {
              uniqueSIDSet.add(sensorObj.SID); // Add SID to the set to mark it as visited

              // Process and modify other properties as needed
              const finalData = {
                companyname: "Dtecto",
                oragnizationnumber: "919582499",
                transStatus: "hold",
                sensorid: sensorObj.SID,
                tranoragnizationnumber: "-",
                id: doc.id,
                company: "-",
                organizationNo: "-",
                sensorId: sensorObj.SID,
                transferCompany: "-",
                transferOrgNo: "-",
                status: "statusIcon",
              };
              this.sensoruserData.push(finalData);
          
            }
          });
          if (this.sensoruserData) {
            this.AddConnectedSensor();
          }
        }
      });
  }
  // add old data to newSensor Collection

  AddConnectedSensor() {
    const newSensorsCollectionRef = this.afs.collection<any>("NewSensors"); // Change 'any' to the actual type of your documents if known
    const uniqueSensorIdSet = new Set();
    // console.clear();

    // Iterate through each item in this.sensoruserData
    this.sensoruserData.forEach((data) => {
      const sensorId = data.sensorid;
     
      // console.log(sensorId, "sensorId")

      // Check if the sensorid is not already in the Set
      if (!uniqueSensorIdSet.has(sensorId)) {
        uniqueSensorIdSet.add(sensorId);

        // Check if a document with the same sensorid already exists in "NewSensors"
        newSensorsCollectionRef
          .ref.where('sensorid', '==', sensorId) // Use .ref before .where
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              // No document with the same sensorid found, add the new document
              newSensorsCollectionRef
                .add(data)
                .then((docRef) => {
                  console.log("Document written with ID: ", docRef.id);
                  this.getNewSensors();
                })
                .catch((error) => {
                  console.error("Error adding document: ", error);
                });
            } else {
              // Document with the same sensorid already exists, handle accordingly
              console.log(`Document with sensorid ${sensorId} already exists in NewSensors`);
            }
          })
          .catch((error) => {
            console.error("Error checking for existing document: ", error);
          });
      }
    });
  }

    // Helper function to format the date
    formatDate(timestamp: number): string {
      const date = new Date(timestamp * 1000);
      return (
        ("0" + date.getFullYear()).slice(-4) +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    }


    onCompanySelected() {
      const selectedCompanyName = this.modalData.companyName;
  
      if (selectedCompanyName) {
        const selectedCompany = this.registerdcompany.find(
          (company) => company.companyname === selectedCompanyName
        );
  
        if (selectedCompany) {
          this.modalData.orgNumber = selectedCompany.oragnizationnumber;
          this.filteredRows = this.rows.filter(row => row.companyname === selectedCompanyName);
        }
      } else {
        this.filteredRows = this.rows;
        console.log('filtered data', this.rows)
      }
    }
  
    onCompanySelecteds() {
      const selectedCompanyName = this.modalData.compyname;
  
      if (selectedCompanyName) {
        const selectedCompany = this.registerdcompany.find(
          (company) => company.companyname === selectedCompanyName
        );
  
        if (selectedCompany) {
          this.modalData.orgnumber = selectedCompany.oragnizationnumber;
        }
      }
    }

     // Function to load status from local storage or API
  loadStatus(): void {
    const savedStatus = localStorage.getItem("status");
    if (savedStatus) {
      const statusArray = JSON.parse(savedStatus);
      for (const savedStatus of statusArray) {
        const row = this.rows.find((row) => row.id === savedStatus.id);
        if (row) {
          row.status = savedStatus.status;
        }
      }
    }
  }

   // Function to toggle the statusChecked flag
 toggleStatus(row) {
  row.statusChecked = !row.statusChecked;
  console.log('row.statusChecked', row.statusChecked)
}

  // Function to save status to local storage or API
  saveStatus(): void {
    const rowIndex = localStorage.getItem("rowIndex");
    if (rowIndex) {
      // Find the row with the matching id
      const updatedRow = this.rows.find((row) => row.id === rowIndex);
      // Update the status for the specific row
      if (updatedRow) {
        updatedRow.transStatus = "success"; // Update this value based on your logic
        localStorage.removeItem("rowIndex"); // Clear the stored row id
      }
    }

  }

  
}



