import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let user = JSON.parse(localStorage.getItem('Id'));
        // let UserRole=JSON.parse(localStorage.getItem('UserRole'))
        if (this.authService.isLoggedIn) {
            

            window.alert("You are not allowed to access this URL!");
            this.router.navigate(['/auth/login'])
            return true;
        }
        return false;
    }
}