import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';

export interface User {
  id: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public user: firebase.User;
  private _sessionId: string;
  public showLoader: boolean = false;
  firestore: any;
  roleAs: any;

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private http: HttpClient,
    private cookieService: CookieService) {

    this.afAuth.authState.subscribe(user => {
      if (user) {

        this.userData = user;
        this._sessionId = this.userData;
        // localStorage.setItem('loggedUserName',this.userData.firstname)

        //  cookieService.set('user', JSON.stringify(this.userData));
        //  localStorage.setItem('user', JSON.stringify(this.userData));
        //   JSON.parse(localStorage.getItem('user'));

      }
    });
  }
  ngOnInit(): void { }

  //sign in function ----------------------------------------------
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified !== true) {
          this.SetUserData(result.user);
          this.SendVerificationMail();
          this.showLoader = true;
        } else {
          this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/auth/login']);
          });
        }
      }).catch((error) => {
        this.toster.error('You have enter Wrong Email or Password.');
      })
  }


  //main verification function -----------------------------------------------
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigateByUrl('/admin/user-registration');
      })
  }




  /* Sign up ----------------------------------------------------------------*/
  SignUp(firstname: string, lastname: string, email: string, password: string): Promise<any> {
    return this.afAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
    // .then(res => {
    //   this.SendVerificationMail();
    //   console.log('Successfully signed up!', res);
    // })
    // .catch(error => {
    //   console.log('Something is wrong:', error.message);
    // });    
  }
  //---Sign in With Facebook---------------------------------------------------------------------
  signInFacebok() {
    return this.afAuth.auth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    )
  }

  //Sign in with Twitter --------------------------------------------------------------
  signInTwitter(): Promise<any> {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }

  //Sign in with Google -------------------------------------------------------------
  GoogleAuth(): Promise<any> {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Get Data And Patch data in form -------------------------------------------------
  getData() {
    return this.afs.collection('UserRegistration').snapshotChanges();
  }

  //Authentication for Login ------------------------------------------------------
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {


        this.ngZone.run(() => {
          this.router.navigate(['/admin/user-registration']);
        });
        this.SetUserData(result.user);

      }).catch((error) => {
        window.alert(error);
      });
  }

  //Set user ---------------------------------------------------------
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      id: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () {
      this.router.navigate(['/']);
    }).catch(function (error) {
    });
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out -----------------------------------------------------------
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();

      this.cookieService.deleteAll('Id', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }


  // user is login ----------------------------------------------------
  get isLoggedIn(): boolean {

    const user = JSON.parse(localStorage.getItem('Id'));
    return (user != null && user.emailVerified != false) ? true : false;

  }




  //service for user registration===========================================
  UserRegistration(firstname, lastname, email, password) {
    return this.afs.collection('UserRegistration').add({
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      emailVerified: false,
      AdminVerification: false,
      UserRole: 'UserAdmin',
      date: new Date().toDateString()

    });
  }
  // Organization number for company ===============================================================
  getCompanydetail(orgnr): Observable<any> {

    return this.http.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`, { responseType: 'json' })
  }

  // Email Verification ===========================================================
  emailverification(id) {

    return this.afs
      .collection("UserRegistration")
      .doc(id)
      .set({ emailVerified: true }, { merge: true });

  }


  // Send Invitaion For User By SuperAdmin
  Companyverification(id) {
    return this.afs.collection('CompanyRegistration').doc(id).update({ emailVerified: true })
  }

  // send Invitaion for Users By CompanyAdmin
  CompanyAdminUserverification(id) {
    return this.afs
      .collection("CompanyRegistration")
      .doc(id)
      .set({ emailVerified: false }, { merge: true });
  }
  // Send Invitation By SuperAdmin For User
  InvitedUserverification(id) {
    return this.afs
      .collection("InvitedUser")
      .doc(id)
      .set({ userVerified: false }, { merge: true });
  }


  // Send Invitation By User Admin For User
  UserAdminverification(id) {
    return this.afs
      .collection("CustomerUsers")
      .doc(id)
      .set({ userVerified: false }, { merge: true });
  }

  // service for comapny registration=================================
  CompanyRegistration(firstname, lastname, email, password, oragnizationnumber, companyname, address) {

    return this.afs.collection('CompanyRegistration').add({
      companyname: companyname,
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      oragnizationnumber: oragnizationnumber,
      CompanyAddress: address,
      emailVerified: false,
      date: new Date().toDateString()
    });
  }

  // send reminder ===================================================
  // sendReminder(email, role) {
  //   const params = JSON.stringify({ 'email': email, 'userrole': role })
  //   return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/sendReminder', JSON.parse(params))
  // }


  // Forgot Passowrd ===================================================================================
  ForgotPassword(passwordResetEmail, type, id) {
    // alert(passwordResetEmail)
    const params = JSON.stringify({ 'email': passwordResetEmail, 'type': type, 'id': id })
    return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/forgetPassword', JSON.parse(params))
  }


  VerifyToken(token, uid) {
    const params = JSON.stringify({ 'token': token, 'uid': uid })
    return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/VerifyToken', JSON.parse(params))
  }

  // Reset Password =================================================================
  resetpassword(updatedpassword, id, type) {
    if (type == 'user') {
      return this.afs.collection("UserRegistration").doc(id).set({ password: updatedpassword }, { merge: true });
    }
    if (type == 'company') {
      return this.afs.collection("CompanyRegistration").doc(id).set({ s: updatedpassword }, { merge: true });
    }
  }


  InviteUser(data) {
    //Firestore CRUD actions example
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("InvitedUser")
        .add(data)
        .then(res => { }, err => reject(err));
    });

  }
  private username = new Subject<string>();
  adminusers$ = this.username.asObservable();
  adminuser(mission: string) {
    this.username.next(mission);
  }
}