import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { sansor } from './sansor.model';
import { map } from 'rxjs/operators';
import { FirebaseService } from 'src/app/shared/services/firebase/todo.service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SensorsService {

  constructor(private firestore: AngularFirestore, private _firebaseService: FirebaseService, private http: HttpClient) { }
  firestorePlacesCollection = this.firestore.collection('Sensors');

  //READ
  sensors$ = this.firestorePlacesCollection.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        console.log("pppppp", p)
        // const Sensors = p.payload.doc;
        // const id = Sensors.id;
        // return { id, ...Sensors.data() } as Sensors;
      });
    })
  );



  getSansorLocation() {
    return this.firestore.collection('Sensors').snapshotChanges();
  }

  getSansorData() {
    return this.firestore.collection('DevicesData').snapshotChanges();
  }
  getimpactDescription()
  {return this.firestore.collection('DtectoMessage').snapshotChanges();}

  getimpactDetectionRange()
  {return this.firestore.collection('DetectionRange').snapshotChanges();}

  getdata(id: number) {
    const productsDocuments = this.firestore.doc('DevicesData/' + id);
    return productsDocuments.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          const id = changes.payload.id;
          return { id, data };
        }))


  }
  //CREATE
  async addPlace(data: sansor): Promise<void> {
    try {
      await this.firestorePlacesCollection.add(data);
    } catch (err) {
      console.log(err);
    }
  }

  //UPDATE
  async updatePlace(id: string, visited: boolean): Promise<void> {
    try {
      await this.firestorePlacesCollection
        .doc(id)
        .set({ visited: !visited }, { merge: true });
    } catch (err) {
      console.log(err);
    }
  }

  //DELETE
  async deletePlace(id: string): Promise<void> {
    try {
      await this.firestorePlacesCollection.doc(id).delete();
    } catch (err) {
      console.log(err);
    }
  }



  sendImpactWarning(mail, data){
    const params = JSON.stringify({'email':mail,'record':data});
    // console.log(data,"sssssssss")
    // console.log(mail)
    
    return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/impactsAlert',JSON.parse(params))
  }


  SnaptoRoadFn(coordinate): Observable<any> {
    return this.http.get(`https://roads.googleapis.com/v1/snapToRoads?path=${coordinate}&interpolate=true&key=AIzaSyAzEaTb6w5jHiUfMvnIawB15omTf_yKGJo`);
    // return "https://roads.googleapis.com/v1/snapToRoads"  {path: coordinate};
  }

  GetDevicedata() {
    return this.http.get('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/getDevicedata')
  }
  public update = new Subject<string>();
  geturlData$ = this.update.asObservable();
  geturl(mission: string) {
    this.update.next(mission);
  }

}
