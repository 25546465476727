
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import {InviteUserService} from '../../services/firebase/invite-user.service'
import { DOCUMENT } from '@angular/common';
import { profile } from 'console';
import { Router, ActivatedRoute, Params, } from '@angular/router';
import { analytics } from 'firebase';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  company:any;
  ProfilePicture:any;
  UseraccessRole:any;
  public isOpenMobile: boolean = false
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService,public Router:Router,private InviteUserService:InviteUserService) {
    translate.setDefaultLang('en');
    var Id=localStorage.getItem('Id')
    this.UseraccessRole=localStorage.getItem('AccessRole');
    if(this.UseraccessRole=='UserAccess'){
      this.InviteUserService.GetCurrentUserData(Id,this.UseraccessRole).subscribe(res=>{
        this.company=res.payload.data();
        this.ProfilePicture=this.company.profilepicture;
      })
    }
    else if(this.UseraccessRole=='InvitedAccess'){
      this.InviteUserService.GetCurrentUserData(Id,this.UseraccessRole).subscribe(res=>{
        this.company=res.payload.data();
        this.ProfilePicture=this.company.UserDetails.profilepicture;
      })
    }
    else if(this.UseraccessRole=='CustomerUsersAccess'){
      this.InviteUserService.GetCurrentUserData(Id,this.UseraccessRole).subscribe(res=>{
        this.company=res.payload.data();
        this.ProfilePicture=this.company.ProfilePicture;
      })
    }
    else if(this.UseraccessRole=='CompanyUsersAccess'){
      this.InviteUserService.GetCurrentUserData(Id,this.UseraccessRole).subscribe(res=>{
        this.company=res.payload.data();
        this.ProfilePicture=this.company.UserDetails.profilepicture;
         // this.ProfilePicture = this.company.UserDetails.profilepicture;
      })
    } 
    else{
      this.InviteUserService.GetCurrentUserData(Id,this.UseraccessRole).subscribe(res => {
      this.company = res.payload.data();
       this.ProfilePicture=this.company.profilepicture;
    });}
    

  }
  
  editprofile(){
    var UserRole=localStorage.getItem('UserRole');
    // console.log(Id,"lllllllll")
    if(UserRole=='admin'){
      this.Router.navigate(['/admin/edit-profile']);
    }
    else if(UserRole == 'operations'|| UserRole == 'workshop' || UserRole == 'Accounting' || UserRole == 'Drivers'){}
    else{}
   
  }

  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
