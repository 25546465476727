import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  eyevalue: boolean = false;
  public newUser = false;
  public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  Isbutton: boolean = false;



  constructor(public authService: AuthService, public afs: AngularFirestore,
    private afauth: AngularFireAuth, private fb: FormBuilder, private route: ActivatedRoute,
    private router: Router, private _toster: ToastrService) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    // this.authService.getData().subscribe(res=>{
    //   for(let i=0;i<res.length;i++)
    //   {
    //     var Finaldata=res[i].payload.doc.data();
    //     console.log(Finaldata,"Dataaaaaaa")
    //   }
    // })
  }

  ngOnInit() {
    let verifyEmail = this.route.snapshot.queryParamMap?.get("verifyEmail");
    let verifyCompany = this.route.snapshot.queryParamMap?.get("verifyCompany");
    let verifyuser = this.route.snapshot.queryParamMap?.get("verifyuser");
    let pendingusers = this.route.snapshot.queryParamMap?.get("uverifiaction");
    let pendingCompany = this.route.snapshot.queryParamMap?.get("cverifiaction");

    //New User Is Registered

    if (verifyEmail || pendingusers) {
      this.authService.emailverification(verifyEmail).then((res) => {
        this._toster.success('Your account verifyed successfully.')
      })
    }

    // Send Invitation
    if (verifyuser) {

      this.authService.InvitedUserverification(verifyuser).then((res) => {
        this._toster.success('Your account verifyed successfully.')
      })
    }

    if (verifyuser) {

      this.authService.UserAdminverification(verifyuser).then((res) => {
        this._toster.success('Your account verifyed successfully.')
      })
    }



    // ---------------------------------
    if (verifyCompany || pendingCompany) {
      this.authService.Companyverification(verifyCompany).then((res) => {
        this._toster.success('Your Company account verifyed successfully.')
      })
    }
  }
  showpassword() {
    let x: any = document.getElementById("pwd");
    if (x.type === "password") {
      x.type = "text";
      this.eyevalue = true;
    } else {
      x.type = "password";
      this.eyevalue = false;
    }
  }


  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth().then((res) => {
      setTimeout(() => {
        window.location.href = '/';
      }, 500)

    })
      .catch((err) => console.log(err));;
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  // loginFacebook() {
  //   this.authService.signInFacebok().then(res => {
  // return this.router.navigateByUrl('/dashboard/default');
  //   });
  // }
  loginFacebook() {
    this.authService.signInFacebok()
      .then((res) => {
        setTimeout(() => {
          window.location.href = '/';
        }, 500)

      })
      .catch((err) => console.log(err));
  }
  register() {
    return this.router.navigate(['/registration'])
  }
  forgetpassword() {
    return this.router.navigate(['/forgotpassword'])
  }
  // Simple Login
  login() {
    this.Isbutton = true;

    //--------------------------------Check the Email and password exit on UserRegistration-------------------------------------------- 
    this.afs.collection('UserRegistration', ref => ref
      .where("email", "==", this.loginForm.value['email'])
      .where("password", "==", this.loginForm.value['password'])).get()
      .subscribe((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            if (doc.data().emailVerified == true) {
              //For SuperAdmin login 
              if (doc.data().UserRole == 'SuperAdmin') {
                this.authService.adminuser('SuperAdmin')
                localStorage.setItem('Id', doc.id)
                localStorage.setItem('AccessRole', 'UserAccess');
                localStorage.setItem('UserRole', doc.data().UserRole)
                this._toster.success('Sign In Successfully.')
                return this.router.navigate(['/admin/user-registration'])
              }
              else {
                // For Normal users 
                // console.log(doc.data().companyDetails, "docdocdocdocdocdoc")

                localStorage.setItem('Id', doc.id)
                localStorage.setItem('UserRole', doc.data().UserRole)
                localStorage.setItem('AccessRole', 'UserAccess');
                this._toster.success('Sign In Successfully.');
                const companyDetails = doc.data().companyDetails;
                // Check if any of the fields except 'About' is blank
                if (
                  !companyDetails.PostalCode ||
                  !companyDetails.Username ||
                  !companyDetails.orgnr ||
                  !companyDetails.country ||
                  !companyDetails.Invoice_Email ||
                  !companyDetails.Firstname ||
                  !companyDetails.City ||
                  !companyDetails.Street ||
                  !companyDetails.Lastname ||
                  !companyDetails.companyName ||
                  !companyDetails.No
                ) {
                  // If any of the fields is blank, navigate to '/users/user-profile'
                  this.router.navigate(['/users/user-profile']);
                } else {
                  // Otherwise, navigate to '/users/incidents-reports'
                  this.router.navigate(['/users/incidents-reports']);
                }
              }
            } else {
              this.Isbutton = false;
              this.loginForm.controls['email'].reset()
              this.loginForm.controls['password'].reset()
              this._toster.error('Your account is not verified. Please check your mail.')
            }
          });
        }
        else {
          // alert("Xxxxxxxxx")
          //--------------------------------Check the Email and password exit on InvitedUser-------------------------------------------- 
          this.afs.collection('InvitedUser', ref => ref.where("UserEmail", "==", this.loginForm.value['email']).where("password", "==", this.loginForm.value['password'])).get()
            .subscribe((querySnapshot) => {
              if (querySnapshot.docs.length > 0) {
                querySnapshot.forEach((doc) => {
                  if (doc.data().userVerified == true) {
                    localStorage.setItem('Id', doc.id)
                    localStorage.setItem('UserRole', doc.data().UserRole)
                    localStorage.setItem('AccessRole', 'InvitedAccess');
                    this._toster.success('Sign In Successfully.')
                    return this.router.navigate(['/users/user-profile']);
                  } else {
                    this.Isbutton = false;
                    this.loginForm.controls['email'].reset()
                    this.loginForm.controls['password'].reset()
                    this._toster.error('Your account is not verified. Please check your mail.')
                  }
                });
              }
              else {
                this.afs.collection('CustomerUsers', ref => ref.where("UserEmail", "==", this.loginForm.value['email']).where("password", "==", this.loginForm.value['password'])).get()
                  .subscribe((querySnapshot) => {
                    if (querySnapshot.docs.length > 0) {
                      querySnapshot.forEach((doc) => {
                        if (doc.data().userVerified == true) {
                          localStorage.setItem('Id', doc.id)
                          localStorage.setItem('UserRole', doc.data().UserRole)
                          localStorage.setItem('AccessRole', 'CustomerUsersAccess');
                          this._toster.success('Sign In Successfully.')
                          return this.router.navigate(['/users/user-profile']);

                        }
                        else {
                          this.Isbutton = false;
                          this.loginForm.controls['email'].reset()
                          this.loginForm.controls['password'].reset()
                          return this._toster.error('Your account is not verified. Please check your mail.')

                        }
                      });
                    }
                    else {
                      this.afs.collection('CompanyRegistration', ref => ref.where("email", "==", this.loginForm.value['email']).where("password", "==", this.loginForm.value['password'])).get()
                        .subscribe((querySnapshot) => {
                          if (querySnapshot.docs.length > 0) {
                            querySnapshot.forEach((doc) => {
                              if (doc.data().emailVerified == true) {
                                this._toster.success('Sign In Successfully.')
                                this.authService.adminuser('CompanyAdmin')
                                localStorage.setItem('Id', doc.id);
                                localStorage.setItem('AccessRole', 'CompanyAccess');

                                const companyDetails = doc.data();
                                // console.log(companyDetails);
                                if (
                                  !companyDetails.postcode ||
                                  !companyDetails.Username ||
                                  !companyDetails.oragnizationnumber ||
                                  !companyDetails.country ||
                                  !companyDetails.invoice_mail ||
                                  !companyDetails.firstname ||
                                  !companyDetails.city ||
                                  !companyDetails.street_name ||
                                  !companyDetails.lastname ||
                                  !companyDetails.companyname ||
                                  !companyDetails.No
                                ) {
                                  return this.router.navigate(['/company/user-profile']);
                                } else {
                                  return this.router.navigate(['/company/incidents-report']);
                                }
                                // return this.router.navigate(['/company/profile']);
                              } else {

                                this.Isbutton = false;
                                this.loginForm.controls['email'].reset()
                                this.loginForm.controls['password'].reset()
                                this._toster.error('Your account is not verified. Please check your mail.')
                              }
                            });
                          } else {

                            this.afs.collection('CompanyUsers', ref => ref.where("Email", "==", this.loginForm.value['email']).where("password", "==", this.loginForm.value['password'])).get()
                              .subscribe((querySnapshot) => {
                                if (querySnapshot.docs.length > 0) {
                                  querySnapshot.forEach((doc) => {
                                    if (doc.data().emailVerified == true) {
                                      this._toster.success('Sign In Successfully.')
                                      this.authService.adminuser('CompanyAdmin')
                                      localStorage.setItem('Id', doc.id);
                                      localStorage.setItem('AccessRole', 'CompanyUsersAccess');
                                      return this.router.navigate(['/company/profile']);
                                    }
                                    else {
                                      this._toster.error('Your account is not verified. Please check your mail.')
                                      this.Isbutton = false;
                                      this.loginForm.controls['email'].reset()
                                      this.loginForm.controls['password'].reset()
                                    }
                                  })
                                }
                                else {
                                  this.Isbutton = false;
                                  this._toster.error('Username or Password incorrect.')
                                  this.loginForm.controls['email'].reset()
                                  this.loginForm.controls['password'].reset()
                                }

                              });


                          }
                        })
                    }

                  });


              }


            })

        }

      })




  }
}
