import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { InviteUserService } from 'src/app/shared/services/firebase/invite-user.service';
import { ToastrService } from 'ngx-toastr';

type UserFields = 'password' | 'reenter_password';
type FormErrors = { [u in UserFields]: string };
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public newUser = false;
  passmatch:boolean = false;
  eyevalue: boolean = false;
  public user: firebase.User;
  public resetpassword: FormGroup;
  public formErrors: FormErrors = {
    'reenter_password': '',
    'password': '',
  };
  public errorMessage: any;
  resetpass:any;
  uid:any;
  tokenvalid:boolean= true;
  doctype:any;


  constructor(public authService: AuthService,
    public toster: ToastrService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private InviteUserService: InviteUserService,
    private router: Router,private route: ActivatedRoute,) {
      this.resetpassword = fb.group({
        password: ['', [Validators.required,  Validators.minLength(8), Validators.maxLength(15)]],
        confirmpassword: ['', [Validators.required,  Validators.minLength(8), Validators.maxLength(15)]],
       } , { validator: this.checkPasswords }
      );

      this.resetpass = this.route.snapshot.queryParamMap.get("token");
      this.uid = this.route.snapshot.queryParamMap.get("uid");
      this.doctype = this.route.snapshot.queryParamMap.get("type");

      if(this.route.snapshot.queryParamMap.get("token") == null){
        this.router.navigate(['/auth/login'])
      } else {
        this.authService.VerifyToken(this.resetpass, this.uid).subscribe(result => {
          if(result['data'] == 'validToken'){
            this.tokenvalid = true;
          } else {
            this.tokenvalid = false;
          }
        console.log(result['data']);
        })
      }
     }
     generatePasswords(passwordLength) {
      var numberChars = "0123456789";
      var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowerChars = "abcdefghijklmnopqrstuvwxyz";
      var SymbolChars="!@#$%^&*()_+~`|}{[]\:;?><,./-=";
     
      var allChars = numberChars + upperChars + lowerChars;
      var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray[3] = SymbolChars;
      randPasswordArray = randPasswordArray.fill(allChars, 4);
      return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
    }
    
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      } 
      return array;
    }
    
  ngOnInit(): void {
  }

 
  
  loginroute(){
    return this.router.navigate(['/auth/login'])
   }

   forgotPassword(){

    return this.router.navigate(['/forgotpassword'])
   }
 
  resetpasswordfn() {
    if (this.resetpassword.value.password != this.resetpassword.value.confirmpassword) {
      this.passmatch = true;
    } else {
      this.passmatch = false;
      this.authService.resetpassword(this.resetpassword.value.password, this.uid, this.doctype).then(result => {
        this.toster.success('Your password update successfully.')
        
        setTimeout(() => {
          return this.router.navigate(['/auth/login']) 
        }, 1500);
      });
    }
}

checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.controls.password.value;
  let confirmPass = group.controls.confirmpassword.value;
  return pass === confirmPass ? null : { notSame: true }
}
showpassword() {
  let x: any = document.getElementById("pwd");
  if (x.type === "password") {
    x.type = "text";
    this.eyevalue = true;
  } else {
    x.type = "password";
    this.eyevalue = false;
  }
}
generatePassword(){    
  this.resetpassword.patchValue({'password':this.generatePasswords(15)})
 }
}
