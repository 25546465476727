import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from "@angular/fire/firestore";
import { InviteUserService } from 'src/app/shared/services/firebase/invite-user.service';
import { VehiclesService } from 'src/app/shared/services/firebase/vehicles.service';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
  profileimagePath: any;
  vehicleimage:any;
  @Input() title: string;
  @Input() componentVal: string;
  @Input() userid: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() data: string;
  
  constructor(private activeModal: NgbActiveModal, private firestore: AngularFirestore, private InviteUserService: InviteUserService, private VehiclesService: VehiclesService) { }

  ngOnInit() {
    if(this.componentVal == 'InvitedUser'){
    if (this.data['profilepicture']) {
      var str = this.data['profilepicture'];
      var splitval = str.substring(str.indexOf("o/") + 1)
      this.profileimagePath = decodeURIComponent(splitval.split('?')[0]);
    } }
    else if(this.componentVal == 'RegisterVehicles'){
      if (this.data['Back_image'] || this.data['Front_image'] || this.data['Left_image'] || this.data['Right_image'] || this.data['FrontPerspective_image'] || this.data['BackPerspective_image']) {
        this.profileimagePath = '/vehicles'+'/'+this.data['Category']+'_'+this.data['Brand']+'_'+this.data['Model']+'_'+this.data['Model_type'];
      } 

      var splitval = this.vehicleimage.substring(this.vehicleimage.indexOf("o/") + 1)
      this.profileimagePath = decodeURIComponent(splitval.split('?')[0]);
    }
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.deleteInvitedUser(this.componentVal, this.userid)
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  deleteInvitedUser(collectionname, contactKey) {
    if (this.profileimagePath && collectionname == 'InvitedUser') {
     // this.InviteUserService.deleteUserStorage(this.profileimagePath);
    }
    else if(this.profileimagePath && collectionname == 'RegisterVehicles'){
//this.VehiclesService.deleteVehiclesStorage(this.profileimagePath)
    }
    //return this.firestore.collection(collectionname).doc(contactKey).delete();

  }


}
