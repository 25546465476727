import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, RouterLinkActive } from '@angular/router';
import { NavService, Menu, UserMenu, company, CustomerUserAdmin, CompanyAdmin } from '../../services/nav.service';
import { AuthService } from '../../services/firebase/auth.service';
import { SensorsService } from '../../services/firebase/sensors.service';
import { InviteUserService } from '../../services/firebase/invite-user.service'

import { async } from 'rxjs/internal/scheduler/async';
import { map } from 'leaflet';
import { slideInRight } from 'ng-animate';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public usermenuitems: UserMenu[];
  public companymenuitems: company[];
  public CustomerUserAdmin: CustomerUserAdmin[];
  public CompanyAdminitems: CompanyAdmin[];
  public url: any;
  public fileurl: any;
  public username: any;
  public ComapnyName: any;
  public FirstName: any;
  public updateUrl;
  user;
  company;
  UserRole;
  loader: any;
  loaderforUser: any;
  userdetails: any;
  ProfilePicture: any;
  companyLogo: any;
  AccessRole: any;
  InvitedUser: any;
  CompanyRegistration: any;
  constructor(private InviteUserServices: InviteUserService, private router: Router, public sensorService: SensorsService, public navServices: NavService, public authService: AuthService,) {
    // Current User Id 
    var Id = localStorage.getItem('Id')
    var UserRole = localStorage.getItem('UserRole');
    this.AccessRole = localStorage.getItem('AccessRole');

    // check User Role ==================================================
    if (UserRole != null) {
      // Data come throught the UserRegistration Table =================================
      if (this.AccessRole == 'UserAccess') {
        this.InviteUserServices.GetCurrentUserData(Id,this.AccessRole).subscribe(res => {
          this.user = res.payload.data()

          this.companyLogo = this.user.companyLogo;
          this.UserRole = this.user.UserRole;
              this.username=this.user.firstname;
          // If the Super Admin Login then showing the menu ==========================================

          // start sidebar menu items functionality for SuperAdmin Menu ==========================================================
          if (this.UserRole == 'SuperAdmin' || this.UserRole == 'superadmin' || this.UserRole == 'admin' || this.UserRole == 'Admin') {
            this.MenuItemforSuperAdmin();
          }
          // End sidebar menu items functionality for SuperAdmin Menu ==========================================================


          else {

            // start sidebar menu items functionality for UserAdmin =====================================================================
            if (this.UserRole == 'UserAdmin' || this.UserRole == 'useradmin') {
              this.MenuItemForUserAdmin();
            }
            // end sidebar menu items  functionality for UserAdmin =====================================================================

            else {
              // start sidebar menu items functionality for Normal Users  =====================================================================   
              this.MenuItemForNormalUsers();
            }
              // end sidebar menu items functionality for Normal Users  =====================================================================   


          }
        })
      }
     
      // check User Role when data come throught the Invited Table or CustomerUser Table ==================================================
      else if (this.AccessRole == 'InvitedAccess' || this.AccessRole == 'CustomerUsersAccess') {
       
        this.loader = 0;
        this.loaderforUser = 0;
       // Data Come throught the InvitedUser table ============================================
        if (this.AccessRole == 'InvitedAccess') {
          this.InviteUserServices.GetCurrentUserData(Id,this.AccessRole).subscribe(res => {
            this.user = res.payload.data()
            this.UserRole = this.user.UserRole;
            this.username = this.user.FirstName;
            this.companyLogo = this.user.CompanyDetails.companyLogo;
            if (this.UserRole == 'UserAdmin' || this.UserRole == 'useradmin') {
              this.MenuItemForUserAdmin();
            }
            else {
              // Data Come throught the InvitedUser table ============================================
              this.MenuItemForNormalUsers();
            }

          });
        }
        else {
       // Data Come throught the CustomerUsers table ============================================
          this.InviteUserServices.GetCurrentUserData(Id,this.AccessRole).subscribe(res => {
            this.user = res.payload.data()
            this.UserRole = this.user.UserRole;
            this.username = this.user.FirstName;
            this.companyLogo = this.user.companyLogo;
            // CustomerUserAdmin can invited other UserAdmin ===============================================
            if (this.UserRole == 'UserAdmin' || this.UserRole == 'useradmin') {
              this.MenuItemForUserAdmin();
            }
            else {
                 // CustomerUserAdmin can invited other users ===============================================
              this.MenuItemForNormalUsers();
            }

          });

        }
      }
    }

    else {
      if(this.AccessRole ==  'CompanyAccess'){
      this.InviteUserServices.GetCurrentUserData(Id,this.AccessRole).subscribe(res => {
        this.company = res.payload.data();
        this.ProfilePicture = this.company.profilepicture;
        this.companyLogo = this.company.companyLogo;
        
      this.ComapnyName = this.company.companyname;
      this.FirstName = this.company.firstname;
        if (this.company.userrole == 'CompanyAdmin' || this.company.userrole == 'companyadmin') {

          this.MenuItemForCompanyAdmin();
          
        }
        else {

          this.MenuItemForCompanyUser();
        }
      })
    }
    else{
      this.InviteUserServices.GetCurrentUserData(Id,this.AccessRole).subscribe(res =>{
       
        this.company =res.payload.data();
        this.ComapnyName = this.company.CompanyDetails.companyname;
        this.FirstName = this.company.FirstName;
        // this.ProfilePicture = this.company.UserDetails.profilepicture;
        this.companyLogo = this.company.CompanyDetails.companyLogo;
        if(this.company.userrole ==  'CompanyAdmin' || this.company.userrole == 'companyadmin'){
          this.MenuItemForCompanyAdmin();
        }
        else{
          this.MenuItemForCompanyUser();
          
          
        }
      })
    }
    }

  }

  // Active Nave state For NormalUsers
    setNavActiveUsers(item) {
    this.usermenuitems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
    }
   // Active Nave state For UserAdmin
    setNavActiveUserAdmin(item) {
    this.CustomerUserAdmin.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
    }
  // Active Nave state For Super Admin
   setNavActive(item) {
    if (item.path == "/admin/sensor-map") {
      this.sensorService.geturl('sensor-map')

    }
    if (item.path == "/admin/user-registration") {
      this.InviteUserServices.geturl('user-registration')
    }
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
    }

  // setNavActiveCompanyUsers For CompanyUsers===========================
    setNavActiveCompanyUsers(item) {
    this.companymenuitems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
   }
   // setNavActiveCompanyAdmin For CompanyAdmin===========================
   setNavActiveCompanyAdmin(item) {
    this.CompanyAdminitems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
   }



 // - -----------------------------------------------------------------------------------TOggle menu for All Users 
  // Click Toggle menu For Super Admin
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
     
    }
   
    item.active = !item.active

  }



  // Click Toggle menu For NormalUsers
  toggletNavActiveforUser(item) {

    if (!item.active) {
      this.usermenuitems.forEach(a => {
        if (this.usermenuitems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active

  }
 // Click Toggle menu For Admin Users
  toggletNavActiveforUserAdmin(item) {

    if (!item.active) {
      this.CustomerUserAdmin.forEach(a => {
        if (this.CustomerUserAdmin.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active

  }



  
 // Click Toggle menu For Only CompanyUsers
  toggletNavActiveforcompanyUsers(item) {

    if (!item.active) {
      this.companymenuitems.forEach(a => {
        if (this.companymenuitems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active

  }


 // Click Toggle menu For Only CompanyAdmin
  toggletNavActiveforcompanyAdmin(item) {

    if (!item.active) {
      this.CompanyAdminitems.forEach(a => {
        if (this.CompanyAdminitems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active

  }






// ----------------------------------------------------------------------------------------
// Function for Super Admin
 MenuItemforSuperAdmin(){
  this.navServices.items.subscribe(menuItems => {
    if (this.UserRole == 'SuperAdmin' || this.UserRole == 'superadmin' || this.UserRole == 'admin' || this.UserRole == 'Admin') {
      if (this.UserRole == 'SuperAdmin' || this.UserRole == 'superadmin' || this.UserRole == 'admin' || this.UserRole == 'Admin') {
        this.loader = 1;
        // this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "User Vehicles"), 1);
        this.menuItems = menuItems;
        this.username = this.user.firstname;
      }
      else {
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Registration"), 1);
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Admin Vehicles"), 1);
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Admin Users"), 1);
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Sensors"), 1);
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Admin Customers"), 1);
        this.menuItems = menuItems.splice(menuItems.findIndex(v => v.title === "Media Bank"), 1);
        this.menuItems = menuItems;
        this.username = this.user.firstname;
        
      }
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        menuItems.filter(items => {
          if (items.path === event.url)
            this.setNavActive(items)
          if (!items.children) return false
          items.children.filter(subItems => {
            if (subItems.path === event.url)
              this.setNavActive(subItems)
            if (!subItems.children) return false
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url)
                this.setNavActive(subSubItems)
            })
          })
        })
      }
    })


  })

 }
 // Function For UserAdmin 
 MenuItemForUserAdmin(){
  this.navServices.CustomerAdminitems.subscribe(CustomerUserAdmin => {
    if (this.UserRole == 'UserAdmin' || this.UserRole == 'useradmin') {
      this.CustomerUserAdmin = CustomerUserAdmin;
      this.username = this.user.FirstName;
      this.companyLogo = this.user.CompanyDetails.companyLogo;
      
    }
    else {
      this.CustomerUserAdmin = CustomerUserAdmin.splice(CustomerUserAdmin.findIndex(v => v.title === "User Profile"), 1);
      this.CustomerUserAdmin = CustomerUserAdmin.splice(CustomerUserAdmin.findIndex(v => v.title === "registration"), 1);
      this.CustomerUserAdmin = CustomerUserAdmin.splice(CustomerUserAdmin.findIndex(v => v.title === "Users"), 1);
      this.CustomerUserAdmin = CustomerUserAdmin.splice(CustomerUserAdmin.findIndex(v => v.title === "Sensors"), 1);
      this.CustomerUserAdmin = CustomerUserAdmin;
      this.username = this.user.firstname;
    }
//  User Admin setNavActiveUserAdmin function =====================================================
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        CustomerUserAdmin.filter(items => {
          if (items.path === event.url)
            this.setNavActiveUserAdmin(items)
          if (!items.children) return false
          items.children.filter(subItems => {
            if (subItems.path === event.url)
              this.setNavActiveUserAdmin(subItems)
            if (!subItems.children) return false
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url)
                this.setNavActiveUserAdmin(subSubItems)
            })
          })
        })
      }
    })

  })
 }
 // Function For NormalUser
 MenuItemForNormalUsers(){
this.navServices.useritems.subscribe(usermenuitems => {
                if (this.UserRole == 'operations' || this.UserRole == 'workshop' || this.UserRole == 'accounting' || this.UserRole == 'drivers' || this.UserRole == 'Operations' || this.UserRole == 'Workshop' || this.UserRole == 'Accounting' || this.UserRole == 'Drivers') {

                  
                  this.usermenuitems = usermenuitems;
                  // this.username = this.user.FirstName;
                  // this.companyLogo = this.user.CompanyDetails.companyLogo;
                }
                else {
                  this.usermenuitems = usermenuitems.splice(usermenuitems.findIndex(v => v.title === "User Profile"), 1);
                  this.usermenuitems = usermenuitems.splice(usermenuitems.findIndex(v => v.title === "registration"), 1);
                  this.usermenuitems = usermenuitems.splice(usermenuitems.findIndex(v => v.title === "Register Vehicles"), 1);

                  this.usermenuitems = usermenuitems;
                  // this.username = this.user.firstname;



                }
          //  User Admin setNavActiveUsers function =====================================================

                this.router.events.subscribe((event) => {
                  if (event instanceof NavigationEnd) {
                    usermenuitems.filter(items => {
                      if (items.path === event.url)
                        this.setNavActiveUsers(items)
                      if (!items.children) return false
                      items.children.filter(subItems => {
                        if (subItems.path === event.url)
                          this.setNavActiveUsers(subItems)
                        if (!subItems.children) return false
                        subItems.children.filter(subSubItems => {
                          if (subSubItems.path === event.url)
                            this.setNavActiveUsers(subSubItems)
                        })
                      })
                    })
                  }
                })

              })
 }
 // Function For Company Users
 MenuItemForCompanyUser(){
  this.navServices.companyitem.subscribe(companymenuitems => {
    if (this.company.companyname != null ||  this.company.CompanyDetails.companyname != null) {
      // this.companymenuitems = companymenuitems.splice(companymenuitems.findIndex(v => v.title === "profile"), 1);
      
      this.companymenuitems = companymenuitems;
    }
    else {
      this.companymenuitems = companymenuitems.splice(companymenuitems.findIndex(v => v.title === "profile"), 1);
      this.companymenuitems = companymenuitems.splice(companymenuitems.findIndex(v => v.title === "Register-vehicle"), 1);
      this.companymenuitems = companymenuitems.splice(companymenuitems.findIndex(v => v.title === "Users"), 1);
      this.companymenuitems = companymenuitems.splice(companymenuitems.findIndex(v => v.title === "Sensors"), 1);
      this.companymenuitems = companymenuitems;
      this.ComapnyName = this.company.companyname;
      this.FirstName = this.company.firstname;


    }
//  Company Users setNavActiveCompanyUsers function =====================================================
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        companymenuitems.filter(items => {
          if (items.path === event.url)
            this.setNavActiveCompanyUsers(items)
          if (!items.children) return false
          items.children.filter(subItems => {
            if (subItems.path === event.url)
              this.setNavActiveCompanyUsers(subItems)
            if (!subItems.children) return false
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url)
                this.setNavActiveCompanyUsers(subSubItems)
            })
          })
        })
      }
    })

  })
 }
 // Function For Company Admin
 MenuItemForCompanyAdmin(){
  this.navServices.CompanyAdminitems.subscribe(CompanyAdminitems => {
    if (this.company.companyname != null) {
      // this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "profile"), 1);
      // this.ComapnyName = this.company.companyname;
      // this.FirstName = this.company.userrole;
      this.CompanyAdminitems = CompanyAdminitems;

    }
    else {
      this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "profile"), 1);
      this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "Registration"), 1);
      this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "Register-vehicle"), 1);
      this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "Users"), 1);
      this.CompanyAdminitems = CompanyAdminitems.splice(CompanyAdminitems.findIndex(v => v.title === "Sensors"), 1);
      this.CompanyAdminitems = CompanyAdminitems;
      this.ComapnyName = this.company.companyname;
      this.FirstName = this.company.userrole;


    }
//  Company Admin setNavActiveCompanyAdmin function =====================================================

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        CompanyAdminitems.filter(items => {
          if (items.path === event.url)
            this.setNavActiveCompanyAdmin(items)
          if (!items.children) return false
          items.children.filter(subItems => {
            if (subItems.path === event.url)
              this.setNavActiveCompanyAdmin(subItems)
            if (!subItems.children) return false
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url)
                this.setNavActiveCompanyAdmin(subSubItems)
            })
          })
        })
      }
    })

  })
 }

 
  //Fileupload
  // onSelectFile(event) {
  //   console.log(event, "event")
  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (event) => {
  //       this.url = event.target.result;

  //     }
  //   }
  // }
}
