import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { NgxSpinnerModule } from "ngx-spinner"; 
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { RegistrationComponent } from './auth/registration/registration.component'; 
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AutologoutService } from './../../src/app/shared/services/firebase/autologout.service'
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule,} from '@angular/material/input';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { MatTableModule ,MatTableDataSource} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator'
import {
  AngularFireStorageModule, 
  AngularFireStorageReference,
  AngularFireUploadTask,
  StorageBucket
} from "@angular/fire/storage";
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { TransferSensorsComponent } from './transfer-sensors/transfer-sensors.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SensoridService } from './shared/services/sensorid.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    TransferSensorsComponent
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig), 
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    MatStepperModule,
    MatButtonModule,
    MatInputModule,
    AppRoutingModule,
    MatPaginatorModule,
    MatTableModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase, "cloud"),
    NgxDatatableModule
  ],
  providers: [AuthService, AdminGuard, SecureInnerPagesGuard, CookieService,AutologoutService,SensoridService,  { provide: StorageBucket, useValue: "your" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
