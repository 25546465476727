import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { InviteUserService } from 'src/app/shared/services/firebase/invite-user.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public newUser = false;
  public user: firebase.User;
  public forgetpassword: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  mailSent:boolean= false;
  mailnotfound:boolean= false;
  public errorMessage: any;
  ForgotButton:Boolean=
  false;

  constructor(public authService: AuthService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private InviteUserService: InviteUserService,
    private router: Router,
    public afs: AngularFirestore,) {
    this.forgetpassword = fb.group({
      email: ['', [Validators.required, Validators.email]],
     
    });
  }

  ngOnInit() {
  }

  loginroute(){
    return this.router.navigate(['/auth/login'])
   }
  // Simple Login
  forgetpasswordfn() { 
    this.ForgotButton = true;
    this.afs.collection('UserRegistration', ref => ref.where("email", "==", this.forgetpassword.value['email'])).get()
    .subscribe((querySnapshot) => {
      if(querySnapshot.docs.length > 0){
        querySnapshot.forEach((doc) => {
        this.authService.ForgotPassword(this.forgetpassword.value['email'],'user',doc.id).subscribe(result => {
          if(result['data']== 'MailSent'){
            this.mailSent = true;
          } else {
            this.mailSent = false;
          }
       });
        })
      }

      else {
        this.afs.collection('CompanyRegistration', ref => ref.where("email", "==", this.forgetpassword.value['email'])).get()
        .subscribe((querySnapshot) => {
          if(querySnapshot.docs.length > 0){
            querySnapshot.forEach((doc) => {
              // console.log("innner")
            this.authService.ForgotPassword(this.forgetpassword.value['email'], 'company',doc.id).subscribe(result => {
              
              if(result['data']== 'MailSent'){
                this.mailSent = true;
              } else {
                this.mailSent = false;
              }
           });
            })
          } else {
            this.ForgotButton=false
            this.mailnotfound = true;
          }
        })
      }
    });
      
  }
  forgotPassword(){
this.mailSent = false;
this.mailnotfound = false;
}

}
