// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // firebase: {
  //   apiKey: "AIzaSyBg_9Ies1ynKu9x0PWToDc1nWdh9xzJMXM",
  // authDomain: "dtecto-10b01.firebaseapp.com",
  // databaseURL: "https://dtecto-10b01.firebaseio.com",
  // projectId: "dtecto-10b01",
  // storageBucket: "dtecto-10b01.appspot.com",
  // messagingSenderId: "741879999930",
  // appId: "1:741879999930:web:8d05949b1eca4755654183",
  // measurementId: "G-SYBHJYKBJ6"
  //   }  
  // firebase: {
  //   apiKey: "AIzaSyDa9zG5WsdXo3cnlqiYPsiNfMvZUuw01KE",
  //   authDomain: "ipo-technology-dev.firebaseapp.com",
  //   databaseURL: "https://ipo-technology-dev.firebaseio.com",
  //   projectId: "ipo-technology-dev",
  //   storageBucket: "ipo-technology-dev.appspot.com",
  //   messagingSenderId: "650631558651",
  //   appId: "1:650631558651:web:d2da2d36409aef62f4475e",
  //   measurementId: "G-52D63J0L5G"
  // }
  firebase: {
    apiKey: "AIzaSyAzEaTb6w5jHiUfMvnIawB15omTf_yKGJo",
    authDomain: "dtecto-admin-c9d89.firebaseapp.com",
    databaseURL: "https://dtecto-admin-c9d89.firebaseio.com",
    projectId: "dtecto-admin-c9d89",
    storageBucket: "dtecto-admin-c9d89.appspot.com",
    messagingSenderId: "655523759042",
    appId: "1:655523759042:web:8eec551b9eeb9594198c5b",
    measurementId: "G-GJL7S2QDBJ"
  }
}; 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
