import { Injectable } from '@angular/core';




export class SensoridService {

  sensorIds: any[] = [];

  constructor() { }

  addSensorId(sensorId: any[]) {
   
    this.sensorIds.push(sensorId);
    console.log('sensorid',sensorId)
  }

  getSensorIds(): any[] {
    return this.sensorIds;
  }
}
