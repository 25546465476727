import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router ,CanDeactivate,NavigationEnd  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(public authService: AuthService,
    public router: Router) 
      { this.router.events.subscribe((ev) => {
          if (ev instanceof NavigationEnd) {
    
            const user = localStorage.getItem("Id");
            const Role = localStorage.getItem('UserRole')
            const AccessRole=localStorage.getItem('AccessRole');
            if ((user && AccessRole && AccessRole === 'CompanyUserAccess' && ev.url.includes('/admin')) || user && AccessRole && AccessRole === 'CompanyUserAccess' && ev.url.includes('/users') || (user && AccessRole && AccessRole === 'CompanyAccess' && ev.url.includes('/admin')) || user && AccessRole && AccessRole === 'CompanyAccess' && ev.url.includes('/users')){ 
              this.router.navigate(['/company/profile']); 
          }
            if ((user && Role && Role === 'UserAdmin' && ev.url.includes('/admin')) || user && Role && Role === 'UserAdmin' && ev.url.includes('/company')  || (user && Role && Role === 'Operations' && ev.url.includes('/admin')) || user && Role && Role === 'Operations' && ev.url.includes('/company') || (user && Role && Role === 'Accounting' && ev.url.includes('/admin')) || user && Role && Role === 'Accounting' && ev.url.includes('/company') || (user && Role && Role === 'Workshop' && ev.url.includes('/admin')) || user && Role && Role === 'Workshop' && ev.url.includes('/company') || (user && Role && Role === 'Drivers' && ev.url.includes('/admin')) || user && Role && Role === 'Drivers' && ev.url.includes('/company') ){ 
                this.router.navigate(['/users/user-profile']);
            }
           
          }
      
     })
    }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for userRole is login or not
    let Role=localStorage.getItem('UserRole')
    let user = localStorage.getItem('Id');
    if (!user) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
    
  }


}

