import { Component } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { AutologoutService } from './../../src/app/shared/services/firebase/autologout.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'endless';
  constructor(private autoLogoutService: AutologoutService) { }

  ngOnInit() {
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#164969"
           },
           button: {
             background: "#ffe000",
             text: "#164969"
           }
         },
         theme: 'edgeless',
         type: 'opt-out',
         layout: 'my-custom-layout',
         
    
        content: {
          "header": 'Cookies and policies!',
          "message": '<span class="cook_head">Cookies and policies</span><br><br>Dtecto uses cookies to give you the best possible user experience. If desired, you can turn off cookies in your browser. By registering as a user you understand and agree to accept and adhere to the following terms and conditions: <span><div class="checkbox_val p-0"><label>Terms of use</label><img src="assets/images/check_checkbox.png"></div><div class="checkbox_val p-0"><label>Privacy Policy</label><img src="assets/images/check_checkbox.png"></div><div class="checkbox_val p-0"><label>Cookies Policy</label><img src="assets/images/check_checkbox.png"></div></span>',
          "dismiss": 'Got it!',
          "allow": 'OK',
        //  "deny": 'Decline',
        //  "link": 'Learn more',
        //  "href": 'https://www.cookiesandyou.com',
          "close": '&#x274c;',
          "policy": 'Cookie Policy',
          "target": '_blank',
        }
       });
  }
}

