import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { Control } from 'leaflet';
import { FirebaseService } from 'src/app/shared/services/firebase/todo.service';
import { User } from './../../shared/model/users';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { stat } from 'fs';
import { InviteUserService } from 'src/app/shared/services/firebase/invite-user.service';
import { DotsData } from 'ngx-owl-carousel-o/lib/models/navigation-data.models';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  user1: User = new User();
  eyevalue: boolean = false;
  submitted = false;
  radioButtonvalue: boolean = true;signupFormOrgNumber: any;
;
  public newUser = false;
  public user: firebase.User;
  InvitedAdminId: any;
  public signupForm: FormGroup;
  emailsendtouser: boolean = false;
  signupButton: boolean = false;

  companyname = '';
  comapnyaddress = '';
  companydata = [];
  constructor(private __InvitedUserService: InviteUserService, private _service: FirebaseService, public afs: AngularFirestore, private route: ActivatedRoute, private _toster: ToastrService, public authService: AuthService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private router: Router) {



    this.signupForm = fb.group({
      reactiveRadio: [true, [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      // companyName: ['', [Validators.required]],
      // OrgNumber: ['', [Validators.required]],
    });

  }
  generatePasswords(passwordLength) {
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var SymbolChars = "!@#$%^&*()_+~`|}{[]\:;?><,./-=";

    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = SymbolChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(randPasswordArray.map(function (x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  get f() { return this.signupForm.controls; }

  isPrivate: boolean = true;

  ngOnInit(): void {
    this.InvitedAdminId = this.route.snapshot.queryParamMap?.get("id");
    let email = this.route.snapshot.queryParamMap?.get("email");
    let fname = this.route.snapshot.queryParamMap?.get("fname");
    let lastname = this.route.snapshot.queryParamMap?.get("lastname");
    this.signupForm.patchValue({
      email: email,
      firstname: fname,
      lastname: lastname
    })


    this.signupForm.controls['reactiveRadio'].valueChanges.subscribe((state: any) => {
      this.radioButtonvalue = state;
      if (state === true) {

        this.isPrivate = true;
        this.signupForm.removeControl('companyName');
        this.signupForm.removeControl('OrgNumber');
      }
      else {
        this.signupForm.addControl('OrgNumber', new FormControl(''));
        this.signupForm.addControl('companyName', new FormControl('', Validators.required));
        this.isPrivate = true;
        // this.signupButton=false;
      }
    })
  }



  showpassword() {
    let x: any = document.getElementById("pwd");
    if (x.type === "password") {
      x.type = "text";
      this.eyevalue = true;
    } else {
      x.type = "password";
      this.eyevalue = false;
    }
  }

  // Simple Login
  signupfn() {
    var CheckAdmindata: any;
    this.signupButton = true;
    //debugger;
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }

    //for user registration ======================
    if (this.radioButtonvalue == true) {
      this.user1.firstName = this.signupForm.value['firstname'];
      this.user1.lastName = this.signupForm.value['lastname'];
      this.user1.password = this.signupForm.value['password'];
      this.user1.email = this.signupForm.value['email'];
      this.user1.type = this.signupForm.value['reactiveRadio'];

      // check email id already registerd in database or not==============
      if (this.InvitedAdminId) {
        // Check InvitedByAdmin is exit on CompanyRegistration
        this.__InvitedUserService.getDataForCompany(this.InvitedAdminId).subscribe(res => {
          if (res) {
            // add user in CompanyUsers
            return;
          }
          else {
            // Check InvitedByAdmin is exit on CustomerUsers
            this.__InvitedUserService.getDataForcustomerUser(this.InvitedAdminId).subscribe(res => {
              if (res) {
                // add User In CustomerUsers
                return;
              }
              else {
                // Check InvitedByAdmin is exit on InvitedUser
                this.__InvitedUserService.getDataForUser(this.InvitedAdminId).subscribe(res => {
                  if (res) {
                    // add User In CustomerUsers
                    return;
                  }
                  else {
                    // Check InvitedByAdmin is exit on UserRegistration
                    this.__InvitedUserService.getData(this.InvitedAdminId).subscribe(res => {
                      // add User In CustomerUsers
                      if (res) {
                        return;
                      }
                      else {
                        // Check InvitedByAdmin is exit on CompanyUsers

                        this.__InvitedUserService.getDataForCompanyUser(this.InvitedAdminId).subscribe(res => {
                          // add User In CompanyUsers  
                          if (res) {

                            return;

                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        })
      }
      else {
        this.afs.collection('UserRegistration', ref => ref.where("email", "==", this.user1.email)).get()
          .subscribe(ss => { 
            //  Check Email Id Exits or Not 
            if (ss.docs.length != 0) {
              this._toster.error('Mail id already registered.')
              return;
            }
            else {
              this.afs.collection('InvitedUser', ref => ref.where("UserEmail", "==", this.user1.email)).get()
                .subscribe(responseforminviteuser => {
                  //  Check Email Id Exits or Not
                  if (ss.docs.length != 0 || responseforminviteuser.docs.length != 0) {
                    this._toster.error('Mail id already registered.')
                    return;
                  }
                  else {
                    this.afs.collection('CustomerUsers', ref => ref.where("UserEmail", "==", this.user1.email)).get()
                      .subscribe(responsefromcustomeruser => {
                        //  Check Email Id Exits or Not
                        if (ss.docs.length != 0 || responseforminviteuser.docs.length != 0 || responsefromcustomeruser.docs.length != 0) {
                          this._toster.error('Mail id already registered.')
                          return;
                        }
                        else {
                          // if email id not registerd then submit new data in databae ---------------------
                          this.authService.UserRegistration(this.signupForm.value['firstname'], this.signupForm.value['lastname'], this.signupForm.value['email'], this.signupForm.value['password']).then(res => {

                            this._toster.success("Successfully Signed up!");
                            this.emailsendtouser = true;
                          }).catch(error => {
                            console.log('Something is wrong:', error.message);
                            this._toster.error(error.message)
                          });

                        }
                      })

                  }
                })
            }
            this.signupButton = true;
          })
      }
    }
    else {
      this.signupButton = true;
      this.signupFormOrgNumber = this.signupForm.value['OrgNumber'].replace(/\s/g, "");
      // alert(this.signupFormOrgNumber);
      this.afs.collection('CompanyRegistration', ref => ref.where("email", "==", this.signupForm.value['email'])).get()
        .subscribe(ss => {
          if(ss.docs.length !=0){
            this._toster.error(' Mail id already registered.')
            return null;
          }
          else{
             
            this.afs.collection('CompanyUsers', ref => ref.where("UserEmail", "==", this.signupForm.value['email'])).get()
            .subscribe(responsefromCompanyUsers => {
              if(ss.docs.length !=0   || responsefromCompanyUsers.docs.length !=0) {
                this._toster.error(' Mail id already registered.')
                return null;
              }
              else{
                   //  --------------- Get company data through Orgnation number-----------
            this.authService.getCompanydetail(this.signupFormOrgNumber).subscribe(res => {
              this.companyname = res['navn'];
              this.comapnyaddress = res.forretningsadresse;
              this.authService.CompanyRegistration(this.signupForm.value['firstname'], this.signupForm.value['lastname'], this.signupForm.value['email'], this.signupForm.value['password'],
              this.signupFormOrgNumber, this.companyname, this.comapnyaddress).then(res => {
                  this.emailsendtouser = true;
                  this._toster.success("Successfully Signed up!")
                });
            }, error => {
              //  --------------- If Orgnation number is Not Valid then -----------
              this.authService.CompanyRegistration(this.signupForm.value['firstname'], this.signupForm.value['lastname'], this.signupForm.value['email'], this.signupForm.value['password'],
              this.signupFormOrgNumber, this.signupForm.value['companyName'], 'address not found').then(res => {

                  this.emailsendtouser = true;
                  this._toster.success("Successfully Signed up!")
                  this._toster.error('Not able to find Company deatil through Org. Number.');
                });
            });
              } 
            })   
          } 
        })
    }
  }
  login() {
    return this.router.navigate(['/auth/login'])
  }


  generatePassword() {
    this.signupForm.patchValue({ 'password': this.generatePasswords(16) })
  }
}
