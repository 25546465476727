import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '.././firebase/auth.service'
import { User } from "../../model/users";


@Injectable({
  providedIn: 'root'
})
export class InviteUserService {
  // db: any;
  httpClient: any;

  constructor(private firestore: AngularFirestore, private http: HttpClient, private modalService: NgbModal, public afAuth: AngularFireAuth,
    private db: AngularFireDatabase, private AuthService: AuthService) {
    firebase.initializeApp(environment.firebase);
  }
  private basePath = '/Profile_Logo';



  // ---------------------------------------Profile Component Functionality start ---------------------------------------------------

  /*----------------Following Access Role -------------------------------------------*/
  //CompanyUsersAccess
  //CompanyAccess
  //CustomerUsersAccess
  //InvitedAccess
  //UserAccess

  //======================= Here we Get the CurrentUserData ====================================
  GetCurrentUserData(id, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore.collection('UserRegistration').doc(id).snapshotChanges();
    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore.collection('InvitedUser').doc(id).snapshotChanges();
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore.collection('CustomerUsers').doc(id).snapshotChanges();
    }
    else if (AccessRole == 'CompanyAccess') {
      return this.firestore.collection('CompanyRegistration').doc(id).snapshotChanges();
    }
    else if (AccessRole == 'CompanyUsersAccess') {
      return this.firestore.collection('CompanyUsers').doc(id).snapshotChanges();
    }

  }

  // Update the password =============================================== 
  UpdateUserPassword(id, UserProfile, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore.collection('UserRegistration').doc(id).update({ 
        password: UserProfile.value['Newpassword'], 
      });
    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore.collection('InvitedUser').doc(id).update({ 
        password: UserProfile.value['Newpassword'], 
      });
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore.collection('CustomerUsers').doc(id).update({ 
        password: UserProfile.value['Newpassword'], 
      });
    }
    else if (AccessRole == 'CompanyAccess') {
      return this.firestore.collection('CustomerUsers').doc(id).update({ 
        password: UserProfile.value['Newpassword'], 
      });
    }
    else if (AccessRole == 'CompanyUsersAccess') {
      return this.firestore.collection('CompanyUsers').doc(id).update({ 
        password: UserProfile.value['Newpassword'],       
      });
    }
  }
  // Update the company Details ================================================


  UpdateUserPics(id, UserProfile, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore.collection('UserRegistration').doc(id).update({
        Propics: UserProfile.value['profilepicture'], 
      });
    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore.collection('InvitedUser').doc(id).update({ 
        Propics: UserProfile.value['profilepicture'], 
      });
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore.collection('CustomerUsers').doc(id).update({
        Propics: UserProfile.value['profilepicture'], 
      });
    }
    else if (AccessRole == 'CompanyAccess') {
      return this.firestore.collection('CustomerUsers').doc(id).update({
        Propics: UserProfile.value['profilepicture'], 
      });
    }
    else if (AccessRole == 'CompanyUsersAccess') {
      return this.firestore.collection('CompanyUsers').doc(id).update({ 
        Propics: UserProfile.value['profilepicture'],       
      });
    }
  }

  UpdateCompanyDetails(id, companyDetail, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore.collection('UserRegistration').doc(id).update({
      'companyDetails': companyDetail.value

      })
    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore
        .collection("InvitedUser")
        .doc(id)
        .update({
          'CompanyDetails.companyname': companyDetail.controls['companyName'].value,
          'CompanyDetails.userfirstname': companyDetail.controls['Firstname'].value,
          'CompanyDetails.userlastname': companyDetail.controls['Lastname'].value,
          'CompanyDetails.orgnr': companyDetail.controls['orgnr'].value,
          'CompanyDetails.street_name': companyDetail.controls['Street'].value,
          'CompanyDetails.No': companyDetail.controls['No'].value,
          'CompanyDetails.postcode': companyDetail.controls['PostalCode'].value,
          'CompanyDetails.city': companyDetail.controls['City'].value,
          'CompanyDetails.country': companyDetail.controls['country'].value,
          'CompanyDetails.invoice_mail': companyDetail.controls['Invoice_Email'].value,
          'CompanyDetails.about': companyDetail.controls['About'].value,
          // 'CompanyDetails.userPropics': companyDetail.controls['userPropics'].value 
          // 'CompanyDetails.companyLogo': companyDetail.controls['companyLogo'].value

        })
        .catch(error => {
          console.log(error, "Error:----");
        });
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore
        .collection("CustomerUsers")
        .doc(id)
        .update({
          'CompanyDetails.companyname': companyDetail.controls['companyName'].value,
          'CompanyDetails.userfirstname': companyDetail.controls['Firstname'].value,
          'CompanyDetails.userlastname': companyDetail.controls['Lastname'].value,
          'CompanyDetails.orgnr': companyDetail.controls['orgnr'].value,
          'CompanyDetails.street_name': companyDetail.controls['Street'].value,
          'CompanyDetails.No': companyDetail.controls['No'].value,
          'CompanyDetails.postcode': companyDetail.controls['PostalCode'].value,
          'CompanyDetails.city': companyDetail.controls['City'].value,
          'CompanyDetails.country': companyDetail.controls['country'].value,
          'CompanyDetails.about': companyDetail.controls['About'].value,
          // 'CompanyDetails.Website': companyDetail.controls['Website'].value,
          'CompanyDetails.invoice_mail': companyDetail.controls['Invoice_Email'].value,
          // 'CompanyDetails.companyLogo': companyDetail.controls['companyLogo'].value

        })
        .catch(error => {
          console.log(error, "Error:----");
        });
    }
    else if (AccessRole == 'CompanyAccess') {
      return this.firestore
        .collection("CompanyRegistration")
        .doc(id)
        .update({

          'companyname': companyDetail.controls['companyName'].value,
          'country': companyDetail.controls['country'].value,
          'Username': companyDetail.controls['Username'].value,
          'firstname': companyDetail.controls['Firstname'].value,
          'lastname': companyDetail.controls['Lastname'].value,
          'oragnizationnumber': companyDetail.controls['orgnr'].value,
          'street_name': companyDetail.controls['Street'].value,
          'No': companyDetail.controls['No'].value,
          'postcode': companyDetail.controls['PostalCode'].value,
          'city': companyDetail.controls['City'].value,
          'invoice_mail': companyDetail.controls['Invoice_Email'].value,
          'About': companyDetail.controls['About'].value

        })
        .catch(error => {
          console.log(error, "Error:----");
        });
    }

    else if (AccessRole == 'CompanyUsersAccess') {
      return this.firestore
        .collection("CompanyUsers")
        .doc(id)
        .update({

          'companyname': companyDetail.controls['companyName'].value,
          'CompanyDetails.companyname':companyDetail.controls['companyName'].value,
          'CompanyDetails.country': companyDetail.controls['country'].value,
          'CompanyDetails.Username': companyDetail.controls['Username'].value,
          'CompanyDetails.firstname': companyDetail.controls['Firstname'].value,
          'CompanyDetails.lastname': companyDetail.controls['Lastname'].value,
          'CompanyDetails.orgnr': companyDetail.controls['orgnr'].value,
          'CompanyDetails.street_name': companyDetail.controls['Street'].value,
          'CompanyDetails.No': companyDetail.controls['No'].value,
          'CompanyDetails.postcode': companyDetail.controls['PostalCode'].value,
          'CompanyDetails.city': companyDetail.controls['City'].value,
          'CompanyDetails.invoice_mail': companyDetail.controls['Invoice_Email'].value,
          'CompanyDetails.About': companyDetail.controls['About'].value

        })
        .catch(error => {
          console.log(error, "Error:----");
        });

    }

  }


  // Patch Data in Company Details Form -======================================================
  getData(id: number) {
    const userDocument = this.firestore.doc('UserRegistration/' + id);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          return { data };
        }))
  }
  getDataForUser(id: number) {
    const userDocument = this.firestore.doc('InvitedUser/' + id);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          return { data };
        }))
  }
  getDataForcustomerUser(id: number) {
    const userDocument = this.firestore.doc('CustomerUsers/' + id);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          return { data };
        }))
  }
  getDataForCompany(id: number) {
    const userDocument = this.firestore.doc('CompanyRegistration/' + id);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          return { data };
        }))

  }
  getDataForCompanyUser(id: number) {
    const userDocument = this.firestore.doc('CompanyUsers/' + id);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          return { data };
        }))

  }
  // --------------------------------------- Profile Component Functionality End ---------------------------------------------------
  // --------------------------------------- Registration Component Functionality start For only (SuperAdmin) --------------------------------------------
  //Send Invitation by SuperAdmin
  InviteUser(companydetails, userdetail, firstname, email, role) {
    return this.firestore.collection('InvitedUser').add({
      FirstName: firstname,
      UserEmail: email,
      UserRole: role,
      UserDetails: userdetail.value,
      CompanyDetails: companydetails.value,
      userVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + firstname,
      date: new Date().toDateString()
    });
  }

  updateInviteuser(id, validationForm, companyDetail) {
    return this.firestore
      .collection("InvitedUser")
      .doc(id)
      .update({
        'UserDetails.userfirstname': validationForm.controls['userfirstname'].value,
        'UserDetails.userlastname': validationForm.controls['userlastname'].value,
        'UserDetails.useremail': validationForm.controls['useremail'].value,
        'UserDetails.mobile': validationForm.controls['mobile'].value,
        'UserDetails.userrole': validationForm.controls['userrole'].value,
        'UserDetails.area': validationForm.controls['area'].value,
        'UserDetails.profilepicture': validationForm.controls['profilepicture'].value,
        'UserRole': validationForm.controls['userrole'].value,
        'CompanyDetails.companyname': companyDetail.controls['companyname'].value,
        'CompanyDetails.orgnr': companyDetail.controls['orgnr'].value,
        'CompanyDetails.street_name': companyDetail.controls['street_name'].value,
        'CompanyDetails.No': companyDetail.controls['No'].value,
        'CompanyDetails.postcode': companyDetail.controls['postcode'].value,
        'CompanyDetails.city': companyDetail.controls['city'].value,
        'CompanyDetails.country': companyDetail.controls['country'].value,
        'CompanyDetails.Website': companyDetail.controls['Website'].value,
        'CompanyDetails.invoice_mail': companyDetail.controls['invoice_mail'].value,
        'CompanyDetails.companyLogo': companyDetail.controls['companyLogo'].value

      })
      .catch(error => {
        console.log(error, "Error:----");
      });


  }
  // --------------------------------------- Registration Component Functionality end  (SuperAdmin)--------------------------------------------

  // ---------------------------------------Registration Component Funtionality start for UserAdmin & Company Admin -------------------------------
  // sendReminderByAllAdmin ------------------------------------------
  sendReminder(id, firstname, email, role, type) {
    const params = JSON.stringify({ 'id': id, 'firstname': firstname, 'email': email, 'userrole': role, 'ComponentType': type })
    return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/sendReminder', JSON.parse(params))
  }
  
    // sendInvitationLink ------------------------------------------
    SendInvitationLinks(InviteById,ForInviteUserRole,ForInviteUserEmail,ForInviteUserName) { 
    const params = JSON.stringify({ 'id': InviteById,'ForInviteUserEmail':ForInviteUserEmail,"ForInviteUserName":ForInviteUserName ,"ForInviteUserRole":ForInviteUserRole})
    console.log(params,"pppppp") 
    return this.http.post('https://us-central1-dtecto-admin-c9d89.cloudfunctions.net/SendInvitation', JSON.parse(params))
    }
  


  //Send Invitation by UserAdmin
  InviteUserAdmin(userdetail, UserAdminId) {
    return this.firestore.collection('CustomerUsers').add({
      FirstName: userdetail.value['userfirstname'],
      UserEmail: userdetail.value['useremail'],
      UserRole: userdetail.value['userrole'],
      UserDetails: userdetail.value,
      // CompanyDetails: companydetails.value,
      userVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + userdetail.value['userfirstname'],
      date: new Date().toDateString()
      // FirstName: userdetail.value['userfirstname'],
      // LastName: userdetail.value['userlastname'],
      // UserEmail: userdetail.value['useremail'],
      // UserRole: userdetail.value['userrole'],
      // UserMobile: userdetail.value['mobile'],
      // UserArea: userdetail.value['area'],
      // ProfilePicture: userdetail.value['profilepicture'],
      // userVerified: false,
      // AdminVerification: true,
      // password: 'Dtecto@' + userdetail.value['userfirstname'],
      // ReferralId: UserAdminId,
      // date: new Date().toDateString()
    });
  }

  //Send Invitation by CompanyAdmin
  invitecompanyadmin(validationForm, companyDetail, UserAdminId) {
    return this.firestore.collection('CompanyUsers').add({

      FirstName: validationForm.controls['userfirstname'].value,
      Email: validationForm.controls['useremail'].value,
      userrole: validationForm.controls['userrole'].value,
      UserDetails: validationForm.value,
      CompanyDetails: companyDetail.value,
      companyname:companyDetail.controls['companyname'].value,
      emailVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + validationForm.controls['userfirstname'].value,
      date: new Date().toDateString()
    });
  }


  // Invite Functionality ---------------------------------------------------------------------------
  UpdateUserProfileDetails(id, validationForm, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore
        .collection("UserRegistration")
        .doc(id)
        .update({
          'firstname': validationForm.controls['userfirstname'].value ? validationForm.controls['userfirstname'].value : '-' ,
          'lastname': validationForm.controls['userlastname'].value ? validationForm.controls['userlastname'].value : '-',
          'email': validationForm.controls['useremail'].value ? validationForm.controls['useremail'].value : '-',
          'mobile': validationForm.controls['mobile'].value ? validationForm.controls['mobile'].value : '-',
          'UserRole': validationForm.controls['userrole'].value ? validationForm.controls['userrole'].value : '-',
          'area': validationForm.controls['area'].value ? validationForm.controls['area'].value : '-',
          'profilepicture': validationForm.controls['profilepicture'].value ? validationForm.controls['profilepicture'].value : '',
        })
        .catch(error => {
          console.log(error, "Error:----");
        });
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore
        .collection("CustomerUsers")
        .doc(id)
        .update({
          'FirstName': validationForm.controls['userfirstname'].value ? validationForm.controls['userfirstname'].value : '-',
          'LastName': validationForm.controls['userlastname'].value ? validationForm.controls['userlastname'].value : '-',
          'UserEmail': validationForm.controls['useremail'].value ? validationForm.controls['useremail'].value : '-',
          'UserMobile': validationForm.controls['mobile'].value ? validationForm.controls['mobile'].value : '-',
          'UserRole': validationForm.controls['userrole'].value ? validationForm.controls['userrole'].value : '-',
          'UserArea': validationForm.controls['area'].value ? validationForm.controls['area'].value : '-',
          'ProfilePicture': validationForm.controls['profilepicture'].value ? validationForm.controls['profilepicture'].value : '',
        })
        .catch(error => {
          console.log(error, "Error:----");
        });

    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore
        .collection("InvitedUser")
        .doc(id)
        .update({
          'UserDetails.userfirstname': validationForm.controls['userfirstname'].value ? validationForm.controls['userfirstname'].value : '-',
          'UserDetails.userlastname': validationForm.controls['userlastname'].value ? validationForm.controls['userlastname'].value : '-',
          'UserDetails.useremail': validationForm.controls['useremail'].value ? validationForm.controls['useremail'].value : '-',
          'UserDetails.mobile': validationForm.controls['mobile'].value ? validationForm.controls['mobile'].value : '-',
          'UserDetails.userrole': validationForm.controls['userrole'].value ? validationForm.controls['userrole'].value : '-',
          'UserRole': validationForm.controls['userrole'].value ? validationForm.controls['userrole'].value : '-',
          'UserDetails.area': validationForm.controls['area'].value ? validationForm.controls['area'].value : '-',
          'UserDetails.profilepicture': validationForm.controls['profilepicture'].value ? validationForm.controls['profilepicture'].value : '',
        })
        .catch(error => {
          console.log(error, "Error:----");
        });
    }
  }

  // ---------------------------------------Registration component Funtionality end For UserAdmin & Company Admin ---------------------------------



  updatecompanyuser(id, validationForm, companyDetail,AccessRole) {
    if(AccessRole == 'CompanyAccess')
    {
      return this.firestore
      .collection("CompanyRegistration")
      .doc(id)
      .update({
        'userfirstname': validationForm.controls['userfirstname'].value ? validationForm.controls['userfirstname'].value : '-',
        'userlastname': validationForm.controls['userlastname'].value ?  validationForm.controls['userlastname'].value : '-',
        'useremail': validationForm.controls['useremail'].value ? validationForm.controls['useremail'].value : '-',
        'mobile': validationForm.controls['mobile'].value ? validationForm.controls['mobile'].value : '-',
        'userrole': validationForm.controls['userrole'].value ? validationForm.controls['userrole'].value : '-' ,
        'area': validationForm.controls['area'].value ? validationForm.controls['area'].value : '',
        'profilepicture': validationForm.controls['profilepicture'].value ? validationForm.controls['profilepicture'].value : '',

        'companyname': companyDetail.controls['companyname'].value ? companyDetail.controls['companyname'].value : '-',
        'oragnizationnumber': companyDetail.controls['orgnr'].value ? companyDetail.controls['orgnr'].value : '-',
        'street_name': companyDetail.controls['street_name'].value ? companyDetail.controls['street_name'].value : '-',
        'No': companyDetail.controls['No'].value ? companyDetail.controls['No'].value : '-',
        'postcode': companyDetail.controls['postcode'].value ? companyDetail.controls['postcode'].value : '-',
        'city': companyDetail.controls['city'].value ? companyDetail.controls['city'].value : '-',
        'country': companyDetail.controls['country'].value ? companyDetail.controls['country'].value : '-',
        'Website': companyDetail.controls['Website'].value ? companyDetail.controls['Website'].value : '-',
        'invoice_mail': companyDetail.controls['invoice_mail'].value ? companyDetail.controls['invoice_mail'].value : '-',
        'companyLogo': companyDetail.controls['companyLogo'].value ? companyDetail.controls['companyLogo'].value : ''

      })
      .catch(error => {
        console.log(error, "Error:----");
      });
    }
    else if(AccessRole == 'CompanyUsersAccess'){
      return this.firestore
      .collection("CompanyUsers")
      .doc(id)
      .update({
        'UserDetails.userfirstname': validationForm.controls['userfirstname'].value,
        'UserDetails.userlastname': validationForm.controls['userlastname'].value,
        'UserDetails.useremail': validationForm.controls['useremail'].value,
        'UserDetails.mobile': validationForm.controls['mobile'].value,
        'userrole': validationForm.controls['userrole'].value,
        'UserDetails.area': validationForm.controls['area'].value,
        'UserDetails.profilepicture': validationForm.controls['profilepicture'].value ? validationForm.controls['profilepicture'].value :'',
        'companyname': companyDetail.controls['companyname'].value,
        'CompanyDetails.companyname': companyDetail.controls['companyname'].value,
        'CompanyDetails.orgnr': companyDetail.controls['orgnr'].value,
        'CompanyDetails.street_name': companyDetail.controls['street_name'].value,
        'CompanyDetails.No': companyDetail.controls['No'].value,
        'CompanyDetails.postcode': companyDetail.controls['postcode'].value,
        'CompanyDetails.city': companyDetail.controls['city'].value,
        'CompanyDetails.country': companyDetail.controls['country'].value,
        'CompanyDetails.Website': companyDetail.controls['Website'].value,
        'CompanyDetails.invoice_mail': companyDetail.controls['invoice_mail'].value,
        'CompanyDetails.companyLogo': companyDetail.controls['companyLogo'].value ? companyDetail.controls['companyLogo'].value : ''

      })
      .catch(error => {
        console.log(error, "Error:----");
      });
    }
  }
  //Send Invitation by SuperAdmin
  InviteUserBySuperadmin(InviteUserFrom) {
    // .value.userfirstname,this.InviteUserFrom.value.useremail,this.InviteUserFrom.value.userrole
    return this.firestore.collection('InvitedUser').add({
      FirstName: InviteUserFrom.value.userfirstname,
      UserEmail: InviteUserFrom.value.useremail,
      UserRole: InviteUserFrom.value.userrole,
      UserDetails: InviteUserFrom.value,
      // CompanyDetails:companydetails.value, 
      userVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + InviteUserFrom.value.userfirstname,
      date: new Date().toDateString()
    });
  }
  //Send Invitation by SuperAdmin
  InviteUserByUserdmin(InviteUserFrom,UserAdminId) {
    // .value.userfirstname,this.InviteUserFrom.value.useremail,this.InviteUserFrom.value.userrole
    return this.firestore.collection('CustomerUsers').add({
      FirstName: InviteUserFrom.value.userfirstname,
      UserEmail: InviteUserFrom.value.useremail,
      UserRole: InviteUserFrom.value.userrole,
      UserDetails: InviteUserFrom.value,
      ReferralId: UserAdminId,
      // CompanyDetails:companydetails.value, 
      userVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + InviteUserFrom.value.userfirstname,
      date: new Date().toDateString()
    });
  }

  InviteUserByCompanyAdmin(InviteUserFrom,UserAdminId) {
    // .value.userfirstname,this.InviteUserFrom.value.useremail,this.InviteUserFrom.value.userrole
    return this.firestore.collection('CompanyUsers').add({
      FirstName: InviteUserFrom.value.userfirstname,
      Email: InviteUserFrom.value.useremail,
      userrole: InviteUserFrom.value.userrole,
      UserDetails: InviteUserFrom.value,
      ReferralId: UserAdminId,
      // CompanyDetails:companydetails.value, 
      emailVerified: false,
      AdminVerification: true,
      password: 'Dtecto@' + InviteUserFrom.value.userfirstname,
      date: new Date().toDateString()
    });
  }
  // Admin verifiaction for user
  RegisterdUserVerification(id, value) {
    let status: any
    if (value == false) {
      return this.firestore
        .collection("UserRegistration")
        .doc(id)
        .set({ AdminVerification: true }, { merge: true });
    }
    else {
      return this.firestore
        .collection("UserRegistration")
        .doc(id)
        .set({ AdminVerification: false }, { merge: true });
    }
  }
  // CompanyAdmin verifiaction for user
  RegisterdCompanyUserVerification(id, value) {
    let status: any
    if (value == false) {
      return this.firestore
        .collection("CompanyUsers")
        .doc(id)
        .set({ AdminVerification: true }, { merge: true });
    }
    else {
      return this.firestore
        .collection("CompanyUsers")
        .doc(id)
        .set({ AdminVerification: false }, { merge: true });
    }
  }


  // Admin verifiaction for Comapny
  RegisterdCompanyVerification(id, value) {
    let status: any
    if (value == false) {
      return this.firestore
        .collection("CompanyRegistration")
        .doc(id)
        .set({ AdminVerification: true }, { merge: true });
    }
    else {
      return this.firestore
        .collection("CompanyRegistration")
        .doc(id)
        .set({ AdminVerification: false }, { merge: true });
    }
  }

  CompanyRegistration() {
    return this.firestore.collection('CompanyRegistration').snapshotChanges();
  }
  getInvitedCustomerAdminUsers() {
    return this.firestore.collection('CustomerUsers').snapshotChanges();
  }
  getInvitedUsers() {
    return this.firestore.collection('InvitedUser').snapshotChanges();
  }
  GetRegisterdUser() {
    return this.firestore.collection('UserRegistration').snapshotChanges();
  }

  getinvitedUsers(id: number) {
    const productsDocuments = this.firestore.doc('InvitedUser/' + id);
    return productsDocuments.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          const id = changes.payload.id;
          return { id, data };
        }))


  }


  getvehicledata(id) {
    return this.firestore
      .collection("RegisterVehicles")
      .snapshotChanges();
  }
  getRegisterVehicles() {
    return this.firestore.collection('RegisterVehicles').snapshotChanges();
  }
  // getRegisterVehiclesIcon() {
  //   return this.firestore.collection('RegisterVehicleIcon').snapshotChanges();
  // }
  getUserRegisterVehicles() {
    return this.firestore.collection('CustomerVehicles').snapshotChanges();
  }
  DeleteRegisterVehicles(id) {
    return this.firestore.collection('RegisterVehicles').doc(id).delete();
  }

  //main verification function 
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
      })
  }


  // Model =====================================================
  public confirm(
    title: string,
    component: string,
    val: string,
    data: string,
    message: string,

    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'md' | 'lg' = 'md'): Promise<boolean> {


    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.componentVal = component;
    modalRef.componentInstance.userid = val;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.data = data;


    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }



  // When Delete the User By Super Admin
  deleteInvitedUser(id) {
    return this.firestore.collection('InvitedUser').doc(id).delete();
  }
   // When Delete the User By Super Admin
   deleteRegisterVehicle(id) {
    return this.firestore.collection('CustomerVehicles').doc(id).delete();
  }
  deleteRegisterdUser(id) {
    return this.firestore.collection('UserRegistration').doc(id).delete();
  }


  // When Delete the User By UserAdmin
  DeleteUser(id) {
    return this.firestore.collection('CustomerUsers').doc(id).delete();
  }
  // Get the match Data from the Customers Users 
  getdatafromcustomertable() {
    return this.firestore.collection("CustomerUsers").snapshotChanges();
  }
  // Get the match Data from the company Users
  getdatafromCompanyUserstable() {
    return this.firestore.collection("CompanyUsers").snapshotChanges();
  }
  // When Delete the User BY Company Admin
  DeleteCompanyUsers(contactKey) {
    return this.firestore.collection('CompanyUsers').doc(contactKey).delete();
  }



  //delete company and user profile logo from storage
  deleteUserStorage(data) {
    const storageRef = firebase.storage().ref();
    return storageRef.child(data).delete();
  }


  public update = new Subject<string>();
  geturlData$ = this.update.asObservable();
  geturl(mission: string) {
    this.update.next(mission);
  }

  //============================================= manager user functionality ==========================================================
  updatetheroleininvited(Id, userrole) {
    return this.firestore.collection("InvitedUser").doc(Id).update({ 'UserDetails.userrole': userrole, 'UserRole': userrole })
  }
  updatetheroleinregistered(Id, userrole) {
    return this.firestore.collection("UserRegistration").doc(Id).update({ 'UserRole': userrole })
  }
  UpdatetheRoleByUserAdmin(Id, userrole) {
    return this.firestore.collection("CustomerUsers").doc(Id).update({ 'UserDetails.userrole': userrole, 'UserRole': userrole })
  }
  UpdatetheRoleByCompanyAdmin(Id, userrole) {
    return this.firestore.collection("CompanyUsers").doc(Id).update({ 'UserDetails.userrole': userrole, 'userrole': userrole })
  }
  // ============================================ manager user end ==============================================================


  // Here We Add THe counter for model in given table =================================================================
  AddCounterForUsers(Id, data, AccessRole) {
    if (AccessRole == 'UserAccess') {
      return this.firestore.collection("UserRegistration").doc(Id).update({ 'counter': data })
    }
    else if (AccessRole == 'InvitedAccess') {
      return this.firestore.collection("InvitedUser").doc(Id).update({ 'counter': data })
    }
    else if (AccessRole == 'CustomerUsersAccess') {
      return this.firestore.collection("CustomerUsers").doc(Id).update({ 'counter': data })
    }
    else if (AccessRole == 'CompanyAccess') {
      return this.firestore.collection("CompanyRegistration").doc(Id).update({ 'counter': data })
    }
    else if (AccessRole == 'CompanyUsersAccess') {
      return this.firestore.collection("CompanyUsers").doc(Id).update({ 'counter': data })
    }
  }
}
