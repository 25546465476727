import { Injectable, HostListener } from "@angular/core";
import { truncate } from "fs";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";

// Menu for Admin
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}
// Menu for CustomerUserAdmin
export interface CustomerUserAdmin {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: CustomerUserAdmin[];
}

// Menu for User
export interface UserMenu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: UserMenu[];
}

// Menu for Company
export interface company {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: company[];
}
// Menu for CompanyAdmin
export interface CompanyAdmin {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: CompanyAdmin[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;
  CheckUser: any;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    this.CheckUser = localStorage.getItem("UserRole");
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
  // list of admin
  MENUITEMS: Menu[] = [
    {
      title: "Registration",
      icon: "server",
      type: "sub",
      active: true,
      children: [
        {
          path: "/admin/user-registration",
          title: "Registration",
          type: "link",
        },
        { path: "/admin/edit-profile", title: "Edit Profile", type: "link" },
        {
          path: "/admin/select-plan-sensors",
          title: "Select Plan and Sensors",
          type: "link",
        },
        { path: "/admin/manage-users", title: "Manage Users", type: "link" },
        // { path: '/admin/edit-plans', title: 'Edit Plans', type: 'link' },
      ],
    },
    {
      title: "Admin Users",
      icon: "globe",
      type: "sub",
      active: false,
      children: [
        // { path: '/admin/userlist', title: 'Invited Users', type: 'link' },
        // { path: '/admin/registered-user', title: 'Registered Users', type: 'link', },
        {
          path: "/admin/registered-company",
          title: "Registered Company",
          type: "link",
        },
        { path: "/admin/pending-users", title: "Pending Users", type: "link" },
        {
          path: "/admin/pending-companies",
          title: "Pending Companies",
          type: "link",
        },

        // { path: '/admin', title: 'Register/Delete Users', type: 'link' },
        // { path: '/dashboard', title: 'List Admin Users', type: 'link' },
        // { path: '/dashboard', title: 'Edit Admin Users', type: 'link' },
        // { path: '/dashboard', title: 'Register/Delete Admin', type: 'link' }
      ],
    },
    {
      title: "Incidents & Reports",
      icon: "eye",
      type: "sub",
      active: false,
      children: [
        {
          path: "/admin/incidents-report",
          title: "List of incidents",
          type: "link",
        },
      ],
    },
    {
      title: "Add Vehicle",
      icon: "eye",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/add-vehicles", title: "Add Vehicle", type: "link" },
        { path: "/admin/vehicles", title: "List Vehicles", type: "link" },
        {
          path: "/admin/manage-vehicle",
          title: "Manage Vehicle",
          type: "link",
        },
        {
          path: "/admin/manage-vehicle-icons",
          title: "Manage Vehicle Icon",
          type: "link",
        },
      ],
    },
    {
      title: "media bank",
      icon: "eye",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/media-bank", title: "Media-Bank", type: "link" },
        { path: "/admin/admin-media", title: "Admin-Media", type: "link" },
      ],
    },
    // {
    // 	title: 'User Vehicles', icon: 'eye', type: 'sub', active: false, children: [
    // 		{ path: '/admin/user-vehicles', title: 'User Vehicle', type: 'link' },
    // 		{ path: '/admin/all-vehicles', title: 'All Vehicles List', type: 'link' },
    // 	]
    // },
    {
      title: "Sensors",
      icon: "eye",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/sensors", title: "Sensor", type: "link" },
        {
          path: "/admin/sensor-registered",
          title: "Add New Sensor",
          type: "link",
        },
        {
          path: "/admin/register-vehicle-sensors",
          title: "Register/Delete Sensors",
          type: "link",
        },
        {
          path: "/admin/transfer-sensor",
          title: "Transfer Sensor",
          type: "link",
        },
        { path: "/admin/sensor-map", title: "Sensor Map", type: "link" },
        {
          path: "/admin/sensor-impacts",
          title: "Sensor Impacts",
          type: "link",
        },
        {
          path: "/admin/impact-description",
          title: "Impact Description",
          type: "link",
        },
        {
          path: "/admin/impact-detection-range",
          title: "Impact Detection Range",
          type: "link",
        },
        { path: "/admin/sensor-button", title: "Sensor Buttons", type: "link" },
        {
          path: "/admin/connect-sensor",
          title: "Add Sensors To Vehicle",
          type: "link",
        },
      ],
    },

    {
      title: "Sales and Support",
      icon: "eye",
      type: "sub",
      active: false,
      children: [{ path: "/admin/demo", title: "Demo", type: "link" }],
    },

    {
      title: "Admin Customers",
      icon: "server",
      type: "sub",
      active: false,
      children: [
        //{ path: '/admin/user-registration', title: 'Registration', type: 'link' },
        {
          path: "/admin/define-access-levels",
          title: "Define Access Levels",
          type: "link",
        },
        {
          path: "/admin/select-modules",
          title: "Select Modules",
          type: "link",
        },
        { path: "/admin/order", title: "Order", type: "link" },
        // { path: '/admin/edit-profile', title: 'Edit Profile', type: 'link' },
        {
          path: "/admin/create-reports",
          title: "Create Reports",
          type: "link",
        },
        {
          path: "/admin/receive-notifications",
          title: "Receive Notifications",
          type: "link",
        },
        {
          path: "/admin/book-service",
          title: "Book Service/Repair",
          type: "link",
        },
        {
          path: "/admin/send-service-feedback",
          title: "Send Service Feedback",
          type: "link",
        },
        {
          path: "/admin/receive-service-feedback",
          title: "Receive Service Feedback",
          type: "link",
        },
      ],
    },
  ];
  // Array

  //   Sidebar Menu For NormalUsers(operations,accounting,drivers,workshop)
  MENUITEMSFORUSER: UserMenu[] = [
    {
      title: "Incident & Reports",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/users/incidents-reports",
          title: "List Of Incidents",
          type: "link",
        },
      ],
    },
    {
      title: "user profile",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/users/user-profile", title: "user profile", type: "link" },
      ],
    },
    {
      title: "Registration",
      icon: "unlock",
      type: "sub",
      active: false,
      children: [
        { path: "/users/registration", title: "Registration", type: "link" },
        {
          path: "/users/select-plan-sensor",
          title: "Select Plan And Sensors",
          type: "link",
        },
      ],
    },
    {
      title: "Register Vehicles",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/users/register-vehicles",
          title: "Register Vehicles",
          type: "link",
        },
        { path: "/users/user-vehicles", title: "User Vehicles", type: "link" },
      ],
    },
  ];

  // sidebar Menu For The UserAdmin
  MENUITEMSFORADMINUSER: CustomerUserAdmin[] = [
    {
      title: "Incident & Reports",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/users/incidents-reports",
          title: "List Of Incidents",
          type: "link",
        },
      ],
    },
    {
      title: "user profile",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/users/user-profile", title: "User Profile", type: "link" },
      ],
    },
    {
      title: "Registration",
      icon: "unlock",
      type: "sub",
      active: false,
      children: [
        { path: "/users/registration", title: "Registration", type: "link" },
        {
          path: "/users/select-plan-sensor",
          title: "Select Plan And Sensors",
          type: "link",
        },
        { path: "/users/manage-user", title: "Manage User", type: "link" },
      ],
    },
    {
      title: "Sensors",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/users/sensor", title: "Sensors", type: "link" },
        { path: "/users/sensor-map", title: "Sensor Map", type: "link" },
        {
          path: "/users/sensor-impacts",
          title: "Sensor Impacts",
          type: "link",
        },
        {
          path: "/users/add-sensor-to-vehicle",
          title: "Add Sensor to vehicle",
          type: "link",
        },
        {
          path: "/users/register-delete sensors",
          title: "Register Delete Sensors",
          type: "link",
        },
      ],
    },
    {
      title: "Register Vehicles",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/users/register-vehicles",
          title: "Register Vehicles",
          type: "link",
        },
        { path: "/users/user-vehicles", title: "User Vehicles", type: "link" },
      ],
    },
  ];

  //   Sidebar Menu For Company NormalUsers(operations,accounting,drivers,workshop)
  MENUITEMSFORCOMPANY: company[] = [
    {
      title: "Incident & Reports",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/company/incidents-report",
          title: "List Of Incidents",
          type: "link",
        },
        {
          path: "/company/Sensor-Impacts",
          title: "Sensor Impacts",
          type: "link", 
        },
      ],
    },
    {
      title: "profile",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [{ path: "/company/profile", title: "profile", type: "link" }],
    },
    {
      title: "registration",
      icon: "unlock",
      type: "sub",
      active: false,
      children: [
        { path: "/company/registration", title: "Registration", type: "link" },
        {
          path: "/company/select-plan-and-sensors",
          title: "select-plan-and-sensors",
          type: "link",
        },
      ],
    },
    {
      title: "register-vehicle",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/company/register-vehicle",
          title: "Register-vehicle",
          type: "link",
        },
        {
          path: "/company/company-vehicle",
          title: "Company-Vehicle",
          type: "link",
        },
        {
          path: "/company/connect-sensor",
          title: "Connect Sensor",
          type: "link",
        },
      ],
    },
  ];

  //   Sidebar Menu For CompanyAdmin

  MENUITEMSFORCOMPANYADMIN: CompanyAdmin[] = [
    {
      title: "Incident & Reports",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/company/incidents-report",
          title: "List Of Incidents",
          type: "link",
        },
      ],
    },
    {
      title: "profile",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [{ path: "/company/profile", title: "profile", type: "link" }],
    },
    {
      title: "registration",
      icon: "unlock",
      type: "sub",
      active: false,
      children: [
        { path: "/company/registration", title: "Registration", type: "link" },
        {
          path: "/company/select-plan-and-sensors",
          title: "select-plan-and-sensors",
          type: "link",
        },
        { path: "/company/manage-user", title: "manage-user", type: "link" },
      ],
    },
    {
      title: "register-vehicle",
      icon: "folder-plus",
      type: "sub",
      active: false,
      children: [
        {
          path: "/company/register-vehicle",
          title: "Register-vehicle",
          type: "link",
        },
        {
          path: "/company/company-vehicle",
          title: "Company-Vehicle",
          type: "link",
        },
      ],
    },

    {
      title: "Sensors",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/company/Sensors", title: "Sensors", type: "link" },
        { path: "/company/Sensor-Map", title: "Sensor Map", type: "link" },
        {
          path: "/company/Sensor-Impacts",
          title: "Sensor Impacts",
          type: "link",
        },
        {
          path: "/company/add-sensor-to-vehicle",
          title: "Add sensor to vehicle",
          type: "link",
        },
        {
          path: "/company/Delete-sensor",
          title: "Delete sensor",
          type: "link",
        },
      ],
    },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  useritems = new BehaviorSubject<UserMenu[]>(this.MENUITEMSFORUSER);
  CustomerAdminitems = new BehaviorSubject<CustomerUserAdmin[]>(
    this.MENUITEMSFORADMINUSER
  );
  companyitem = new BehaviorSubject<company[]>(this.MENUITEMSFORCOMPANY);
  CompanyAdminitems = new BehaviorSubject<CompanyAdmin[]>(
    this.MENUITEMSFORCOMPANYADMIN
  );
}
